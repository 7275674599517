import { useId } from "react"
import styled from "styled-components"
import Values from "values.js"

import { type CommunityPostPollOption } from "@forento/shared/models/community"

import { CheckmarkIcon } from "~/components/Icon"
import { lightTextColor, primaryColor } from "~/utilities/styles"

interface Props {
	option: CommunityPostPollOption
	onChange(isSelected: boolean): void
}

const PollOption: React.FC<Props> = ({ option, onChange }) => {
	const inputId = useId()

	return (
		<Container $isChecked={option.hasUserSelected}>
			<Input checked={option.hasUserSelected} onChange={() => onChange(!option.hasUserSelected)} id={inputId} />
			<Content htmlFor={inputId}>
				<Checkbox $isChecked={option.hasUserSelected}>{option.hasUserSelected && <CheckmarkIcon />}</Checkbox>
				<Label>
					{option.label}
					<Votes>
						({option.votes.count} | {Math.round(option.votes.share * 100)}%)
					</Votes>
				</Label>
				<VoteShareContainer>
					<VoteShare $voteShare={option.votes.share} />
				</VoteShareContainer>
			</Content>
		</Container>
	)
}

const Container = styled.div<{ $isChecked: boolean }>`
	border: 1.4px solid ${props => (props.$isChecked ? primaryColor : "black")};
	border-radius: 8px;
`

const Input = styled.input.attrs({ type: "checkbox" })`
	display: none;
`

const Content = styled.label`
	display: grid;
	grid-template-areas: "checkbox label" "checkbox voteShare";
	grid-template-columns: auto 1fr;
	grid-template-rows: auto auto;
	column-gap: 12px;
	row-gap: 4px;
	align-items: center;
	padding: 8px 12px;
	cursor: pointer;
`

const Checkbox = styled.div<{ $isChecked: boolean }>`
	grid-area: checkbox;
	width: 32px;
	height: 32px;
	border: 1.4px solid ${props => (props.$isChecked ? primaryColor : "black")};
	color: ${primaryColor};
	border-radius: 8px;
`

const Label = styled.p`
	grid-area: label;
`

const Votes = styled.span`
	color: ${lightTextColor};
	margin-left: 8px;
`

const VoteShareContainer = styled.div`
	grid-area: voteShare;
	width: 100%;
	height: 4px;
	border-radius: 2px;
	background-color: ${new Values(primaryColor).tint(90).hexString()};
	overflow: hidden;
`

const VoteShare = styled.div<{ $voteShare: number }>`
	width: ${props => props.$voteShare * 100}%;
	height: 100%;
	background-color: ${primaryColor};
	transition: 0.15s width;
`

export default PollOption
