import { lazy, Suspense, type FC } from "react"
import styled from "styled-components"

import PartialLoadingPage from "~/components/PartialLoadingPage"
import { cardColor, primaryColor } from "~/utilities/styles"

import ChartCategorySelector, { type ChartCategory } from "../ChartCategorySelector"

const Content = lazy(() => import("./Content"))

type Props = {
	label: string
	unit?: string
	values: { name: string; value: number }[]
	periodTitle: string
	selectedChartCategory: ChartCategory
	setSelectedChartCategory: (newValue: ChartCategory) => void
}
const Chart: FC<Props> = ({ label, unit, values, periodTitle, selectedChartCategory, setSelectedChartCategory }) => (
	<Container>
		<Header>
			<ChartCategorySelector selectedValue={selectedChartCategory} onChange={setSelectedChartCategory} />
			<HeaderGroup>
				{values.length > 0 && (
					<LegendContainer>
						<LegendTitle>
							<LegendColor />
							{periodTitle}
						</LegendTitle>
						<LegendTotalValue>
							{values.reduce((value, current) => Math.floor(value + current.value * 100), 0) / 100}
							{unit !== undefined ? ` ${unit}` : ""}
						</LegendTotalValue>
					</LegendContainer>
				)}
			</HeaderGroup>
		</Header>
		<Suspense fallback={<PartialLoadingPage />}>
			<Content label={label} values={values} unit={unit} />
		</Suspense>
	</Container>
)

const Container = styled.div`
	grid-area: chart;
	background-color: ${cardColor.purple};
	border-radius: 12px;
	padding: 32px;
`

const Header = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	margin-bottom: 16px;
`

const HeaderGroup = styled.div`
	display: flex;
	align-items: flex-start;
`

const LegendContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-right: 48px;
`

const LegendTitle = styled.div`
	font-size: 14px;
	display: flex;
	align-items: center;
	margin-bottom: 8px;
`

const LegendColor = styled.div`
	width: 20px;
	height: 6px;
	background-color: ${primaryColor};
	border-radius: 6px;
	margin-right: 10px;
`

const LegendTotalValue = styled.p`
	font-size: 16px;
	font-weight: 700;
`

export default Chart
