import styled from "styled-components"

import Button from "~/components/Button"
import { lightTextColor } from "~/utilities/styles"

import illustration from "./illustration.svg"

const NoMemberships: React.FC<{ onCreateTier: () => void }> = ({ onCreateTier }) => (
	<Container>
		<Illustration src={illustration} alt="Illustration" />
		<Title>Tiers</Title>
		<Text>
			Academy membership is a way for your users to subscribe to you for a recurring fee, and you can choose what
			perks each tier will receive.
		</Text>
		<Button variant="primary" onClick={onCreateTier}>
			Create new tier
		</Button>
	</Container>
)

const Container = styled.div`
	background-color: #fbfaf8;
	border: 1px solid #eeeeed;
	border-radius: 8px;
	padding: 42px;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 12px;
`

const Illustration = styled.img`
	width: 100px;
`

const Title = styled.h2`
	font-weight: 600;
	font-size: 20px;
`

const Text = styled.p`
	color: ${lightTextColor};
	text-align: center;
	max-width: 500px;
`

export default NoMemberships
