import { type DragEndEvent } from "@dnd-kit/core"
import { arrayMove } from "@dnd-kit/sortable"
import { type FC, useLayoutEffect, useState } from "react"
import styled from "styled-components"

import { type IntendedPageType } from "@forento/shared/models/course"
import routes from "@forento/shared/routes"

import Button from "~/components/Button"
import {
	DeleteIcon,
	EditIcon,
	InputIcon,
	QuizIcon,
	TextIcon,
	UploadIcon,
	VideoIcon,
	VimeoIcon,
	YouTubeIcon,
} from "~/components/Icon"
import PartialLoadingPage from "~/components/PartialLoadingPage"
import { useAlert } from "~/contexts/AlertContext"
import SortableListContext from "~/contexts/SortableListContext"
import { useUser } from "~/contexts/UserContext"
import useDropdownMenu from "~/hooks/useDropdownMenu"
import { dangerColor, primaryLightColor } from "~/utilities/styles"
import trpc, { swr } from "~/utilities/trpc"

import EditPageModal from "./EditPageModal"
import Page from "./Page"

interface Props {
	chapterId: number
	editPageId: number | null
	setEditPageId(pageId: number | null): void
	reloadCourse(): Promise<unknown>
}

const ChapterEditor: FC<Props> = ({ chapterId, editPageId, setEditPageId, reloadCourse }) => {
	const user = useUser()
	const alert = useAlert()
	const platform = user.user!.platform!

	const { data: chapter, error, mutate } = swr.course.getChapter.useSWR(chapterId, { revalidateOnFocus: false })

	const [pagesOrder, setPagesOrder] = useState<number[]>([])

	const dropdownMenu = useDropdownMenu([
		{ label: "Upload", icon: UploadIcon, onClick: () => handleCreateNewPage("video") },
		{ label: "YouTube", icon: YouTubeIcon, onClick: () => handleCreateNewPage("youtube") },
		{ label: "Vimeo", icon: VimeoIcon, onClick: () => handleCreateNewPage("vimeo") },
	])

	useLayoutEffect(() => {
		if (chapter == null) {
			setPagesOrder([])
			return
		}
		setPagesOrder(chapter.pages.map(page => page.id))
	}, [chapter])

	if (error) return <p>Failed to load chapter.</p>
	if (chapter === null) return <p>Chapter not found.</p>
	if (chapter === undefined)
		return (
			<Container>
				<PartialLoadingPage />
			</Container>
		)

	async function reload() {
		await reloadCourse()
		await mutate()
	}

	const handleEditChapterTitle = async () => {
		const dialog = await alert.input(
			"Edit chapter title",
			"Enter the new title for this chapter",
			"Chapter title",
			chapter.title,
		)
		if (dialog.result === null) return

		try {
			await trpc.course.updateChapter.mutate({ id: chapter.id, data: { title: dialog.result } })
			await reload()
		} catch (error) {
			console.error(error)
		} finally {
			dialog.close()
		}
	}

	const handleDeleteChapter = async () => {
		const dialog = await alert.confirm(
			"Delete chapter",
			"Are you sure you want to delete this chapter? All pages will be deleted as well.",
		)
		if (!dialog.result) return

		try {
			await trpc.course.deleteChapter.mutate(chapter.id)
			await reload()
		} catch (error) {
			console.error(error)
		} finally {
			dialog.close()
		}
	}

	const handleCreateNewPage = async (type: IntendedPageType) => {
		const page = await trpc.course.createPage.mutate({ chapterId: chapter.id, intendedType: type })
		await reload()
		setEditPageId(page.id)
	}

	const handlePageDragEnd = async (event: DragEndEvent) => {
		const { active, over } = event
		if (over === null || over.id === active.id) return

		const newOrder = arrayMove(
			pagesOrder,
			pagesOrder.indexOf(Number(active.id)),
			pagesOrder.indexOf(Number(over.id)),
		)
		setPagesOrder(newOrder)

		for (const page of chapter.pages) {
			await trpc.course.updatePage.mutate({ id: page.id, data: { sortOrder: newOrder.indexOf(page.id) } })
		}
	}

	const editPage = chapter.pages.find(x => x.id === editPageId) ?? null

	return (
		<Container>
			{dropdownMenu.component}
			<EditPageModal
				isOpen={editPage !== null}
				type={editPage?.intendedType ?? "text"}
				onClose={async args => {
					setEditPageId(null)
					if (args?.reloadCourse) {
						await reload()
					}
				}}
				page={{
					id: editPage?.id ?? -1,
					title: editPage?.title ?? "",
					text: editPage?.text ?? null,
					image: editPage?.image ?? null,
					video: editPage?.video ?? null,
					youTubeVideoId: editPage?.youTubeVideoId ?? null,
					vimeoVideoId: editPage?.vimeoVideoId ?? null,
					inputType: editPage?.inputType ?? null,
					answers: editPage?.quiz?.answers.map(x => ({ ...x, isCorrect: x.isCorrect ?? false })) ?? null,
					attachments: editPage?.attachments ?? [],
					links: editPage?.links ?? [],
				}}
			/>
			<Header>
				<ChapterTitle>
					{chapter.title}
					<ChapterTitleEditButton onClick={handleEditChapterTitle}>
						<EditIcon />
					</ChapterTitleEditButton>
				</ChapterTitle>
				<Actions>
					<DeleteChapterButton onClick={handleDeleteChapter}>
						<DeleteChapterIcon />
					</DeleteChapterButton>
					<Button
						variant="primary"
						onClick={`//${platform.domain.platform}${routes.platform.course.player(
							chapter.courseId,
						)}?email=${encodeURIComponent(user.user!.email)}`}
						newTab
					>
						Preview
					</Button>
				</Actions>
			</Header>
			<Content>
				<CreatePageContainer>
					<CreatePageLabel>Create a new page</CreatePageLabel>
					<CreatePageTypes>
						<CreatePageType {...dropdownMenu.button}>
							<CreatePageTypeIcon as={VideoIcon} />
							Video
						</CreatePageType>
						<CreatePageType onClick={() => handleCreateNewPage("text")}>
							<CreatePageTypeIcon as={TextIcon} />
							Text
						</CreatePageType>
						<CreatePageType onClick={() => handleCreateNewPage("input")}>
							<CreatePageTypeIcon as={InputIcon} />
							Input
						</CreatePageType>
						<CreatePageType onClick={() => handleCreateNewPage("quiz")}>
							<CreatePageTypeIcon as={QuizIcon} />
							Quiz
						</CreatePageType>
					</CreatePageTypes>
				</CreatePageContainer>
				<PagesList>
					<SortableListContext items={pagesOrder} onDragEnd={handlePageDragEnd}>
						{[...chapter.pages]
							.sort((a, b) => pagesOrder.indexOf(a.id) - pagesOrder.indexOf(b.id))
							.map(page => (
								<Page
									key={page.id}
									page={page}
									order={pagesOrder.indexOf(page.id)}
									onClick={() => setEditPageId(page.id)}
								/>
							))}
					</SortableListContext>
				</PagesList>
			</Content>
		</Container>
	)
}

const Container = styled.div`
	max-width: 1000px;
	width: 100%;
	padding: 0 0 48px;
	display: flex;
	flex-direction: column;
`

const Header = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 32px;
`

const ChapterTitle = styled.div`
	font-weight: 600;
	font-size: 26px;
	display: flex;
	align-items: center;
	gap: 6px;
`

const ChapterTitleEditButton = styled(Button)`
	width: 24px;
	height: 24px;
`

const Actions = styled.div`
	display: flex;
	gap: 12px;
`

const DeleteChapterButton = styled(Button)`
	width: 42px;
	height: 42px;
	background-color: ${dangerColor};
	padding: 8px;
	border-radius: 6px;
`

const DeleteChapterIcon = styled(DeleteIcon)`
	width: 100%;
	height: 100%;
	color: white;
`

const Content = styled.div`
	width: 100%;
	max-width: 787px;
	align-self: center;
`

const CreatePageContainer = styled.div`
	background-color: ${primaryLightColor};
	display: flex;
	justify-content: space-between;
	border-radius: 10px;
	padding: 16px 26px;
	margin-bottom: 22px;
`

const CreatePageLabel = styled.p`
	font-weight: 600;
	font-size: 16px;
	margin-right: 96px;
`

const CreatePageTypes = styled.div`
	display: flex;
	gap: 64px;
`

const CreatePageType = styled(Button)`
	font-weight: 500;
	font-size: 16px;
	display: flex;
	align-items: center;
	gap: 6px;
`

const CreatePageTypeIcon = styled.div`
	width: 24px;
	height: 24px;
`

const PagesList = styled.ol`
	list-style: none;
	display: flex;
	flex-direction: column;
	gap: 32px;
`

export default ChapterEditor
