import styled from "styled-components"

import CommunityProfileAvatar from "@forento/shared/components/CommunityProfileAvatar"
import { toDateTimeString } from "@forento/shared/utilities/date"

import Button from "~/components/Button"
import PartialLoadingPage from "~/components/PartialLoadingPage"
import Table from "~/components/Table"
import routes from "~/utilities/routes"
import { swr } from "~/utilities/trpc"

const ProfilesPage: React.FC = () => {
	const { data: profiles, error } = swr.community.listProfiles.useSWR()

	if (error) return <p>Failed to load profiles.</p>
	if (!profiles) return <PartialLoadingPage />
	return (
		<Table
			isCompact
			columns={[
				"Name",
				{ label: "Community register date", tooltip: "When the user created its community profile" },
				{ label: "Register date", tooltip: "When the user registered for the first time on your academy" },
			]}
			data={profiles.map(profile => [
				<Cell as={Button} onClick={routes.community.profile(profile.id)}>
					<CommunityProfileAvatar profile={profile} />
					<ProfileName>
						{profile.firstName} {profile.lastName}
					</ProfileName>
				</Cell>,
				<Cell>{toDateTimeString(profile.registerDate.community)}</Cell>,
				<Cell>{toDateTimeString(profile.registerDate.user)}</Cell>,
			])}
		/>
	)
}

const Cell = styled.div`
	height: 100%;
	display: flex;
	align-items: center;
	gap: 12px;
`

const ProfileName = styled.div`
	font-weight: 600;
	font-size: 14px;
`

export default ProfilesPage
