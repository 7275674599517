import { Decimal } from "decimal.js"
import { type Dispatch, useReducer } from "react"

import { isZeroFractionCurrency } from "@forento/shared/utilities/currency"

import { usePlatform } from "~/contexts/UserContext"

export default function usePrice(defaultValue?: number | string): {
	price: string
	setPrice: Dispatch<string>
	priceValue: number | null
} {
	const platform = usePlatform()!

	const [price, setPrice] = useReducer((current: string, value: string) => {
		if (value === "") return value
		if (isZeroFractionCurrency(platform.currency)) {
			const price = parseInt(value.replace(/\D/g, ""))
			if (isNaN(price)) return current
			return price.toString()
		} else {
			const hasTrailingComma = [".", ","].includes(value[value.length - 1])
			try {
				const price = new Decimal(hasTrailingComma ? value.slice(0, -1) : value)
				if (price.isNaN()) return current
				return price.clamp(1, 999999.99).toDecimalPlaces(2, Decimal.ROUND_FLOOR) + (hasTrailingComma ? "." : "")
			} catch {
				return current
			}
		}
	}, defaultValue?.toString() ?? "")

	return {
		price,
		setPrice,
		priceValue: price.length > 0 ? parseFloat(price) : null,
	}
}
