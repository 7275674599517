import { type InvitedStudent } from "@forento/shared/models/user"

import Table from "~/components/Table"
import { toRelativeDateString } from "~/utilities/date"

const InvitedUsers: React.FC<{ invites: InvitedStudent[] }> = ({ invites }) => (
	<Table
		isCompact
		columns={["Email", "Default membership", "Expires"]}
		data={[...invites]
			.sort((a, b) => b.expireDate.getTime() - a.expireDate.getTime())
			.map(invite => [invite.email, invite.membershipTitle, toRelativeDateString(invite.expireDate)])}
	/>
)

export default InvitedUsers
