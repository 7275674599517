import { useEffect, useMemo, useState } from "react"
import styled, { css } from "styled-components"

import AspectRatio from "@forento/shared/components/AspectRatio"
import Collapse from "@forento/shared/components/Collapse"
import VideoPlayer from "@forento/shared/components/VideoPlayer"
import { type VideoCaptionLanguageCode } from "@forento/shared/models/video"
import { getDataUrlByFile, getFileNameFromPath } from "@forento/shared/utilities/file"

import Button from "~/components/Button"
import { ChevronIcon } from "~/components/Icon"
import ImageUploader from "~/components/ImageUploader"
import InputField, { useRichTextArea } from "~/components/InputField"
import InputLabel from "~/components/InputLabel"
import LoadingIndicator from "~/components/LoadingIndicator"
import VideoCaptions from "~/components/VideoCaptions"
import VideoUploader from "~/components/VideoUploader"
import { useAlert } from "~/contexts/AlertContext"
import { useVideoUploader } from "~/contexts/VideoUploadContext"
import { arrayBufferToBase64 } from "~/utilities/buffer"
import { primaryColor } from "~/utilities/styles"
import trpc from "~/utilities/trpc"

import Attachments from "./Attachments"
import Links from "./Links"
import { ButtonsGroup, ButtonsRow, DeleteMediaButton, DeleteMediaIcon } from "./styles"

type Video = { host: "apiVideo"; apiVideoId: string } | { host: "bunny"; bunnyVideoId: string }

type Props = {
	page: {
		id: number
		title: string
		text: string | null
		video:
			| { host: "apiVideo"; apiVideoId: string }
			| { host: "bunny"; bunnyVideoId: string; thumbnailUrl: string | null; captions: VideoCaptionLanguageCode[] }
			| null
		attachments: { id: number; fileType: string; label: string; url: string }[]
		links: { id: number; label: string; url: string }[]
	}
	onClose(): void
	onSavePage: (data: {
		title: string
		text: string | null
		thumbnailDataUrl?: string | null
		addCaptions: { language: VideoCaptionLanguageCode; base64: string }[]
		removeCaptions: VideoCaptionLanguageCode[]
	}) => Promise<void>
	onDeletePage(): void
}
const EditVideoPageModalContent: React.FC<Props> = ({ page, onClose, onSavePage, onDeletePage }) => {
	const videoUploader = useVideoUploader()
	const alert = useAlert()

	const [title, setTitle] = useState(page.title)
	const text = useRichTextArea({ label: "Text", initialValue: page.text, features: { headings: true, colors: true } })
	const [video, setVideo] = useState<Video | null>(page.video)
	const [thumbnail, setThumbnail] = useState<File | null>(null)
	const [isThumbnailChanged, setThumbnailChanged] = useState(false)
	const [addCaptions, setAddCaptions] = useState<{ language: VideoCaptionLanguageCode; file: File }[]>([])
	const [removeCaptions, setRemoveCaptions] = useState<VideoCaptionLanguageCode[]>([])
	const [isDeletingVideo, setDeletingVideo] = useState(false)

	const [showAdvanced, setShowAdvanced] = useState(false)
	const [isSubmitting, setSubmitting] = useState(false)

	const thumbnailUrl = useMemo(() => thumbnail && URL.createObjectURL(thumbnail), [thumbnail])

	const uploadingVideo = videoUploader.getByPageId(page.id)

	useEffect(() => {
		const abortController = new AbortController()

		setTitle(page.title)
		setVideo(page.video)
		setThumbnail(null)
		setThumbnailChanged(false)
		setAddCaptions([])
		setRemoveCaptions([])

		if (page.video?.host === "bunny" && page.video.thumbnailUrl) {
			fetch(page.video.thumbnailUrl, { signal: abortController.signal })
				.then(async x => ({ name: getFileNameFromPath(x.url), blob: await x.blob() }))
				.then(({ name, blob }) => {
					if (abortController.signal.aborted) return
					setThumbnail(new File([blob], name ?? "Thumbnail", { type: blob.type }))
				})
		}

		return () => {
			abortController.abort()
		}
	}, [page])

	useEffect(() => {
		if (uploadingVideo?.status === "completed") {
			setVideo({ host: "bunny", bunnyVideoId: uploadingVideo.bunnyVideoId })
		} else if (uploadingVideo?.status === "failed") {
			setVideo(null)
		}
	}, [uploadingVideo?.bunnyVideoId, uploadingVideo?.status])

	async function handleDownloadVideo() {
		try {
			const url = await trpc.course.getVideoDownloadUrlByPageId.query(page.id)
			window.open(url, "_blank")
		} catch (error) {
			console.error(error)
		}
	}

	async function handleSave() {
		setSubmitting(true)
		try {
			await onSavePage({
				title,
				text: text.exportEditorState(),
				thumbnailDataUrl: isThumbnailChanged
					? thumbnail
						? await getDataUrlByFile(thumbnail)
						: null
					: undefined,
				addCaptions: await Promise.all(
					addCaptions.map(async x => ({
						language: x.language,
						base64: arrayBufferToBase64(await x.file.arrayBuffer()),
					})),
				),
				removeCaptions,
			})
		} catch (error) {
			console.error(error)
			await alert.show("Error", "Failed to save the page. Please try again.")
		} finally {
			setSubmitting(false)
		}
	}

	return (
		<>
			<InputField label="Title" value={title} onChange={setTitle} />
			{text.element}
			<div>
				<InputLabel>Video</InputLabel>
				<AspectRatio width={{ unit: "percent", value: 100 }} aspectRatio={16 / 9}>
					{video !== null ? (
						<PlayerContainer>
							<VideoPlayer video={video} />
							<DeleteMediaButton
								onClick={async () => {
									if (isDeletingVideo) return
									setDeletingVideo(true)
									try {
										await trpc.course.deletePageVideo.mutate(page.id)
										setVideo(null)
									} finally {
										setDeletingVideo(false)
									}
								}}
							>
								{!isDeletingVideo ? (
									<DeleteMediaIcon />
								) : (
									<DeleteMediaIcon as={LoadingIndicator} color="white" />
								)}
							</DeleteMediaButton>
						</PlayerContainer>
					) : (
						<VideoUploader
							onUpload={async file => {
								await videoUploader.start({
									file,
									pageId: page.id,
									title: title.length > 0 ? title : "Untitled page",
								})
							}}
							progress={uploadingVideo?.status === "uploading" ? uploadingVideo.progress : null}
						/>
					)}
				</AspectRatio>
			</div>
			{(page.video === null || page.video.host === "bunny") && (
				<AdvancedSettings>
					<AdvancedSettingsButton onClick={() => setShowAdvanced(current => !current)}>
						<AdvancedSettingsButtonIcon $isOpen={showAdvanced} />
						{showAdvanced ? "Hide" : "Show"} advanced video settings
					</AdvancedSettingsButton>
					<Collapse isCollapsed={!showAdvanced}>
						<AdvancedSettingsContent>
							<div>
								<InputLabel>Custom thumbnail</InputLabel>
								<ImageUploader
									filePath={thumbnailUrl}
									onUpload={file => {
										setThumbnail(file)
										setThumbnailChanged(true)
									}}
									onDelete={() => {
										setThumbnail(null)
										setThumbnailChanged(true)
									}}
								/>
							</div>
							<div>
								<InputLabel>Captions</InputLabel>
								<VideoCaptions
									captions={page.video?.captions ?? []}
									addCaptions={addCaptions}
									removeCaptions={removeCaptions}
									setAddCaptions={setAddCaptions}
									setRemoveCaptions={setRemoveCaptions}
								/>
							</div>
							<Button variant="secondary" onClick={handleDownloadVideo}>
								Download video
							</Button>
						</AdvancedSettingsContent>
					</Collapse>
				</AdvancedSettings>
			)}
			<Attachments pageId={page.id} attachments={page.attachments} />
			<Links pageId={page.id} links={page.links} />
			<ButtonsRow>
				<ButtonsGroup>
					<Button variant="secondary" onClick={onClose} isDisabled={isSubmitting}>
						Cancel
					</Button>
					<Button
						variant="primary"
						isDisabled={title.trim().length === 0}
						onClick={handleSave}
						isLoading={isSubmitting}
					>
						Save
					</Button>
				</ButtonsGroup>
				<Button variant="primary-danger" onClick={onDeletePage}>
					Delete
				</Button>
			</ButtonsRow>
		</>
	)
}

const PlayerContainer = styled.div`
	position: relative;
`

const AdvancedSettings = styled.div`
	border: 1px dashed ${primaryColor};
	border-radius: 8px;
	padding: 8px;
	font-weight: 500;
	font-size: 16px;
`

const AdvancedSettingsButton = styled(Button)`
	display: flex;
	align-items: center;
	gap: 4px;
	font-weight: 500;
	font-size: 16px;
`

const AdvancedSettingsButtonIcon = styled(ChevronIcon)<{ $isOpen: boolean }>`
	width: 24px;
	height: 24px;
	transition: transform 0.15s;

	${props =>
		props.$isOpen &&
		css`
			transform: rotate(90deg);
		`}
`

const AdvancedSettingsContent = styled.div`
	padding: 8px;
	display: flex;
	flex-direction: column;
	gap: 16px;
`

export default EditVideoPageModalContent
