export const communityAccess = ["view", "react", "comment", "post"] as const
export type CommunityAccess = (typeof communityAccess)[number]

export type ThinCommunityProfile = {
	id: number
	email: string
	firstName: string
	lastName: string
	avatarFilePath: string
	registerDate: { community: Date; user: Date }
}

export interface PublicCommunityProfile {
	id: number
	platformId: number
	firstName: string
	lastName: string
	avatarFilePath: string
	isCreator: boolean
	posts: number
	reactions: number
	comments: number
	gamification: {
		points: number
		currentLevel: {
			tier: number
			label: string
			unlockPoints: number
		} | null
		nextLevel: {
			tier: number
			label: string
			unlockPoints: number
		} | null
	}
	registerDate: {
		community: Date
		user: Date
	}
}

export interface CommunityProfile extends PublicCommunityProfile {
	email: string
}

export interface Conversation {
	otherProfile: PublicCommunityProfile
	lastMessage: {
		text: string
		sendDate: Date
	}
}

export interface CommunityMessage {
	id: number
	text: string
	senderProfileId: number
	receiverProfileId: number
	sendDate: Date
}

export type CommunityChannel = {
	id: number | null
	name: string
	defaultAccess: CommunityAccess | null
	membershipAccess: Record<number, CommunityAccess>
}

export type PersonalizedCommunityChannel = {
	id: number | null
	name: string
	userAccess: CommunityAccess
}

export interface CommunityPostCategory {
	id: number
	label: string
}

export interface DetailedCommunityPostCategory extends CommunityPostCategory {
	platformId: number
	posts: number
}

export type CommunityPost = {
	id: number
	platformId: number
	channel: {
		id: number | null
		name: string
		defaultAccess: CommunityAccess | null
		membershipAccess: Record<number, CommunityAccess>
	}
	profile: {
		id: number
		firstName: string
		lastName: string
		avatarFilePath: string
	}
	category: { label: string } | null
	title: string
	htmlContent: string
	pollOptions: { id: number; label: string; votedProfileIds: number[] }[]
	attachments: { id: number; label: string; filePath: string }[]
	reactedProfileIds: number[]
	commentsCount: number
	createDate: Date
}

export type PersonalizedCommunityPost = {
	id: number
	platformId: number
	channel: { id: number | null; name: string; userAccess: CommunityAccess }
	profile: {
		id: number
		firstName: string
		lastName: string
		avatarFilePath: string
	}
	category: { label: string } | null
	title: string
	htmlContent: string
	pollOptions: CommunityPostPollOption[]
	attachments: { id: number; label: string; filePath: string }[]
	reactionsCount: number
	commentsCount: number
	createDate: Date
	hasUserReacted: boolean
	hasEditAccess: boolean
}

export interface CommunityPostReaction {
	profile: PublicCommunityProfile
}

export interface CommunityPostComment {
	id: number
	profile: {
		id: number
		firstName: string
		lastName: string
		avatarFilePath: string
	}
	text: string
	createDate: Date
	editDate: Date | null
}

export interface CommunityPostPollOption {
	id: number
	label: string
	votes: { count: number; share: number }
	hasUserSelected: boolean
}

export interface CommunityPostAttachment {
	id: number
	label: string
	filePath: string
}

export interface GamificationLevel {
	id: number
	platformId: number
	label: string
	unlockPoints: number
}
