import { type ExternalPurchaseSource } from "./product"

export const chapterDripIntervals = ["day", "week", "month"] as const
export type ChapterDripInterval = (typeof chapterDripIntervals)[number]

export type ThinCourse = {
	id: number
	platformId: number
	sortOrder: number
	title: string
	thumbnailFilePath: string | null
	price: { currency: string; amount: number } | null
	membershipIds: number[]
	issueCertificates: boolean
	commentsEnabled: boolean
	tags: { id: number; label: string }[]
	shortDescription: string
	longDescription: string | null
	chapters: { id: number; title: string; pageIds: number[] }[]
	pageCount: number
	publishDate: Date | null
}

export interface Course {
	id: number
	platformId: number
	sortOrder: number
	title: string
	thumbnailFilePath: string | null
	price: { currency: string; amount: number } | null
	membershipIds: number[]
	issueCertificates: boolean
	commentsEnabled: boolean
	autoApproveComments: boolean
	totalCommentsUnderReview: number
	tags: { id: number; label: string }[]
	shortDescription: string
	longDescription: string | null
	chapterDripInterval: ChapterDripInterval | null
	chapters: { id: number; title: string; pageIds: number[] }[]
	pageCount: number
	sales: number
	enrolledStudents: number
	publishDate: Date | null
}

export type UserCourseAccess =
	| { status: "paywall"; price: { currency: string; amount: number } }
	| { status: "membership-required" }
	| ({
			status: "unlocked"
			pages: { total: number; completed: number }
			progress: number
			firstCompletedPageDate: Date | null
			firstUncompletedPage: {
				id: number
				chapterId: number
			} | null
			resources: { type: "attachment" | "link"; label: string; href: string }[]
			commentsUnderReview: number
	  } & (
			| {
					unlockedCause: "purchased"
					order:
						| { type: "stripe" | "paypal"; id: number }
						| { type: "external"; id: number; createdBy: ExternalPurchaseSource }
			  }
			| { unlockedCause: "free" | "membership" | "tester" | "creator" }
	  ))

export type AnonymousCourseAccess =
	| { status: "paywall"; price: { currency: string; amount: number } }
	| { status: "membership-required" }
	| {
			status: "unlocked"
			unlockedCause: "free"
			pages: { total: number; completed: 0 }
			progress: 0
			firstUncompletedPage: null
			resources: { type: "attachment" | "link"; label: string; href: string }[]
			commentsUnderReview: 0
	  }

export type CourseAccess = AnonymousCourseAccess | UserCourseAccess

export type PersonalizedCourse = {
	id: number
	sortOrder: number
	title: string
	thumbnailFilePath: string | null
	price: { currency: string; amount: number } | null
	membershipIds: number[]
	issueCertificates: boolean
	commentsEnabled: boolean
	tags: { id: number; label: string }[]
	shortDescription: string
	longDescription: string | null
	chapters: { id: number; title: string; pageIds: number[] }[]
	pageCount: number
} & CourseAccess

export const intendedPageTypes = ["text", "video", "youtube", "vimeo", "quiz", "input"] as const
export const coursePageInputTypes = ["single-line", "multi-line"] as const
export type IntendedPageType = (typeof intendedPageTypes)[number]
export type CoursePageInputType = (typeof coursePageInputTypes)[number]
export interface CoursePageAttachment {
	id: number
	label: string
	fileType: string
	url: string
}

export interface CoursePageLink {
	id: number
	label: string
	url: string
}

export interface DetailedCoursePage {
	id: number
	order: number
	title: string
	htmlContent: string | null
	image: {
		filePath: string
		contain: boolean
	} | null
	video:
		| ({ isCompleted: boolean } & (
				| { host: "apiVideo"; apiVideoId: string }
				| { host: "bunny"; bunnyVideoId: string; thumbnailUrl: string | null }
		  ))
		| null
	youTubeVideoId: string | null
	vimeoVideoId: string | null
	quiz: {
		type: "single-answer" | "multi-answer"
		answers: CoursePageAnswer[]
	} | null
	inputType: CoursePageInputType | null
	attachments: CoursePageAttachment[]
	links: CoursePageLink[]
	chapter: {
		id: number
		title: string
		order: number
	}
	course: {
		id: number
		platformId: number
		title: string
		chapterDripInterval: ChapterDripInterval | null
		issueCertificates: boolean
	}
}

export interface CoursePageAnswer {
	id: number
	text: string
}

export interface CourseTester {
	userId: number
	email: string
	firstName: string
	lastName: string
}

export interface CourseInputs {
	chapters: {
		id: number
		title: string
		pages: CourseInputsPage[]
	}[]
}

export interface CourseInputsPage {
	id: number
	title: string
	users: CourseInputsUser[]
}

export interface CourseInputsUser {
	id: number
	firstName: string
	lastName: string
	inputs: {
		id: number
		text: string
		createDate: Date
	}[]
}
