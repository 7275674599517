import { lazy, Suspense, type FC } from "react"
import { Helmet } from "react-helmet-async"
import styled from "styled-components"

import { DeviceIcon } from "~/components/Icon"
import PartialLoadingPage from "~/components/PartialLoadingPage"
import { cardColor } from "~/utilities/styles"

const Content = lazy(() => import("./Content"))

const Devices: FC<{ devices: { label: string; visitsShare: number }[] }> = ({ devices }) => (
	<Container>
		<Helmet>
			<link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Roboto+Mono" />
		</Helmet>
		<Row>
			<Icon as={DeviceIcon} />
			<Title>Devices</Title>
		</Row>
		{devices.every(device => device.visitsShare === 0) ? (
			<NoDataText>No device data</NoDataText>
		) : (
			<Suspense fallback={<PartialLoadingPage />}>
				<Content devices={devices} />
			</Suspense>
		)}
	</Container>
)

const Container = styled.div`
	grid-area: devices;
	background-color: ${cardColor.gray};
	padding: 32px;
	border-radius: 12px;
	display: flex;
	flex-direction: column;
`

const Row = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
	margin-bottom: 32px;
`

const Icon = styled.div`
	width: 32px;
	height: 32px;
`

const Title = styled.h2`
	font-size: 16px;
	color: black;
`

const NoDataText = styled.p`
	font-size: 14px;
	margin-top: 8px;
`

export default Devices
