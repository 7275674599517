import { type DragEndEvent } from "@dnd-kit/core"
import { arrayMove } from "@dnd-kit/sortable"
import { type FC, useState, useLayoutEffect, useMemo } from "react"
import styled from "styled-components"

import { type Course } from "@forento/shared/models/course"

import Button from "~/components/Button"
import Layout, { PageTitle } from "~/components/Layout"
import PartialLoadingPage from "~/components/PartialLoadingPage"
import Tabs from "~/components/Tabs"
import SortableListContext from "~/contexts/SortableListContext"
import trpc, { swr } from "~/utilities/trpc"

import CreateNewCourseModal from "./CreateNewCourseModal"
import DraftCourse from "./DraftCourse"
import PublishedCourse from "./PublishedCourse"

const CoursesPage: React.FC = () => {
	const { data: courses, error, mutate } = swr.course.list.useSWR()

	return (
		<Layout>
			<PageTitle>Courses</PageTitle>
			{error ? (
				<p>Failed to load courses.</p>
			) : courses === undefined ? (
				<PartialLoadingPage />
			) : (
				<Content courses={courses} reload={mutate} />
			)}
		</Layout>
	)
}

type ContentProps = { courses: Course[]; reload(): Promise<unknown> }
const Content: FC<ContentProps> = ({ courses, reload }) => {
	const [selectedTabIndex, setSelectedTabIndex] = useState(0)
	const [isCreateNewCourseModalOpen, setCreateNewCourseModalOpen] = useState(false)
	const [coursesOrder, setCoursesOrder] = useState<number[]>([])

	const publishedCourses = useMemo(() => courses.filter(course => course.publishDate), [courses])
	const draftCourses = courses.filter(course => course.publishDate === null)

	useLayoutEffect(() => {
		setCoursesOrder([...publishedCourses].sort((a, b) => a.sortOrder - b.sortOrder).map(course => course.id))
	}, [publishedCourses])

	function handleDragEnd(event: DragEndEvent) {
		const { active, over } = event
		if (!over || active.id === over.id) return

		const newItems = arrayMove(
			coursesOrder,
			coursesOrder.indexOf(Number(active.id)),
			coursesOrder.indexOf(Number(over.id)),
		)
		setCoursesOrder(newItems)

		for (const course of publishedCourses) {
			trpc.course.update.mutate({ id: course.id, data: { sortOrder: newItems.indexOf(course.id) } })
		}
	}

	return (
		<>
			<Tabs
				selectedTabIndex={selectedTabIndex}
				setSelectedTabIndex={setSelectedTabIndex}
				tabs={[
					{
						title: "Published",
						sticker: publishedCourses.length.toString(),
						content: (
							<SortableListContext items={coursesOrder} onDragEnd={handleDragEnd}>
								<CoursesList>
									{[...publishedCourses]
										.sort((a, b) => coursesOrder.indexOf(a.id) - coursesOrder.indexOf(b.id))
										.map(course => (
											<PublishedCourse
												key={course.id}
												course={{ ...course, publishDate: course.publishDate! }}
												reload={reload}
											/>
										))}
								</CoursesList>
							</SortableListContext>
						),
					},
					{
						title: "Draft",
						sticker: draftCourses.length.toString(),
						content: (
							<CoursesList>
								{draftCourses.map(course => (
									<DraftCourse key={course.id} course={course} reload={reload} />
								))}
							</CoursesList>
						),
					},
				]}
			/>
			<CreateNewCourseButton
				onClick={() => setCreateNewCourseModalOpen(true)}
				reducedMargin={
					(selectedTabIndex === 0 && publishedCourses.length > 0) ||
					(selectedTabIndex === 1 && draftCourses.length > 0)
				}
			>
				Create course
			</CreateNewCourseButton>
			<CreateNewCourseModal
				isOpen={isCreateNewCourseModalOpen}
				closeModal={() => setCreateNewCourseModalOpen(false)}
				onSubmitted={async () => {
					await reload()
					setCreateNewCourseModalOpen(false)
					setSelectedTabIndex(1)
				}}
			/>
		</>
	)
}

const CreateNewCourseButton = styled(Button).attrs({ variant: "primary" })<{ reducedMargin: boolean }>`
	align-self: flex-end;
	margin-top: ${props => (props.reducedMargin ? 6 : 30)}px;
`

const CoursesList = styled.ul`
	list-style: none;
`

export default CoursesPage
