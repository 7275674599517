import { useState } from "react"
import { useNavigate, useParams } from "react-router"
import styled from "styled-components"

import Form from "@forento/shared/components/Form"

import { SubmitButton } from "~/components/Button"
import Checkbox from "~/components/Checkbox"
import { AddIcon } from "~/components/Icon"
import InputField from "~/components/InputField"
import InputLabel from "~/components/InputLabel"
import PartialLoadingPage from "~/components/PartialLoadingPage"
import { useAlert } from "~/contexts/AlertContext"
import { useDisableGoogleSignIn } from "~/contexts/GoogleSignInContext"
import { useUser } from "~/contexts/UserContext"
import routes from "~/utilities/routes"
import { dangerColor } from "~/utilities/styles"
import trpc, { swr } from "~/utilities/trpc"

import Layout, {
	Content,
	FieldInfoText,
	Inputs,
	InputsRow,
	Logo,
	NavigationButton,
	NavigationText,
	Text,
	Title,
} from "../Layout"
import appSumoLogo from "./appSumoLogo.svg"

const AppSumoSignupPage: React.FC = () => {
	const params = useParams()
	const keyFromParams = params.appSumoKey!.trim().toUpperCase()

	const alert = useAlert()
	const user = useUser()
	const navigate = useNavigate()
	useDisableGoogleSignIn()

	const keyFromAPI = swr.user.getAppSumoKey.useSWR(keyFromParams)

	const [keys, setKeys] = useState<string[]>([keyFromParams])
	const [newKey, setNewKey] = useState<string>("")
	const [isCheckingNewKey, setCheckingNewKey] = useState(false)
	const [firstName, setFirstName] = useState("")
	const [lastName, setLastName] = useState("")
	const [email, setEmail] = useState("")
	const [password, setPassword] = useState("")
	const [passwordConfirmation, setPasswordConfirmation] = useState("")
	const [platformName, setPlatformName] = useState("")
	const [isAccountEmailChecked, setAccountEmailChecked] = useState(true)
	const [contactEmail, setContactEmail] = useState("")

	const [error, setError] = useState<string>()
	const [isSubmitting, setSubmitting] = useState(false)

	const handleSignup = async () => {
		setSubmitting(true)
		setError(undefined)

		if (password !== passwordConfirmation) {
			setError("Passwords don't match.")
			setSubmitting(false)
			return
		}

		const body = {
			firstName: firstName.trim(),
			lastName: lastName.trim(),
			email: email.trim(),
			password,
			platformName: platformName.trim(),
			contactEmail: isAccountEmailChecked ? email.trim() : contactEmail.trim(),
			appSumoKeys: keys,
		}

		if ([body.firstName, body.lastName, body.password, body.email, body.platformName].some(x => x.length === 0)) {
			setError("Please fill in all fields.")
			setSubmitting(false)
			return
		}

		try {
			const response = await trpc.user.registerCreatorAppSumo.mutate(body)
			if (response.status === "email-taken") {
				setError("That e-mail address is already associated with an account.")
				setSubmitting(false)
				return
			}

			await user.reload()

			navigate(routes.account.createPlatform())
		} catch {
			setError("Something went wrong. Please try again later.")
			setSubmitting(false)
		}
	}

	const handleAddKey = async () => {
		setCheckingNewKey(true)
		const normalizedKey = newKey.trim().toUpperCase()
		if (keys.includes(normalizedKey)) {
			await alert.show("Key already added", "That key is already added.")
			setCheckingNewKey(false)
			return
		}
		try {
			const response = await trpc.user.getAppSumoKey.query(normalizedKey)
			if (response === null) {
				alert.show("Invalid key", "That key is invalid, or already taken.")
				setCheckingNewKey(false)
				return
			}
			if (response.version !== keyFromAPI.data?.version) {
				alert.show(
					"Invalid key",
					"This key can't be added to your account during sign up. Please contact customer support for assistance.",
				)
				setCheckingNewKey(false)
				return
			}
			setKeys(current => [...current, normalizedKey])
			setNewKey("")
		} catch (error) {
			console.error(error)
		} finally {
			setCheckingNewKey(false)
		}
	}

	return (
		<Layout>
			<LogoContainer>
				<StyledLogo />
				x
				<AppSumoLogo />
			</LogoContainer>
			<Content>
				<Title>
					Register a new account
					<br />
					via AppSumo
				</Title>
				{error !== undefined && <ErrorText>{error}</ErrorText>}

				{keyFromAPI.data === undefined ? (
					<PartialLoadingPage />
				) : keyFromAPI.data !== null ? (
					<>
						<Form onSubmit={handleAddKey}>
							<InputLabel>Redeemed AppSumo codes</InputLabel>
							<Keys>
								{keys.map(key => (
									<li key={key}>
										<Key>{key}</Key>
									</li>
								))}
							</Keys>
							<InputLabel>Add AppSumo codes</InputLabel>
							<NewKeyContainer>
								<NewKeyInput value={newKey} onChange={setNewKey} />
								<NewKeyButton isLoading={isCheckingNewKey}>
									<NewKeyButtonIcon />
								</NewKeyButton>
							</NewKeyContainer>
						</Form>
						<Content as={Form} onSubmit={handleSignup}>
							<Inputs>
								<InputsRow>
									<InputField
										label="First name"
										value={firstName}
										onChange={setFirstName}
										autoComplete="given-name"
									/>

									<InputField
										label="Last name"
										value={lastName}
										onChange={setLastName}
										autoComplete="family-name"
									/>
								</InputsRow>

								<InputField
									inputType="email"
									label="Email"
									value={email}
									onChange={setEmail}
									autoComplete="email"
								/>

								<InputField
									inputType="password"
									label="Password"
									value={password}
									onChange={setPassword}
									autoComplete="new-password"
								/>

								<InputField
									inputType="password"
									label="Confirm password"
									value={passwordConfirmation}
									onChange={setPasswordConfirmation}
									autoComplete="new-password"
								/>

								<InputField label="Academy name" value={platformName} onChange={setPlatformName} />

								<div>
									<StyledCheckbox
										label="Use my account's email address"
										isChecked={isAccountEmailChecked}
										onChange={setAccountEmailChecked}
									/>
									{isAccountEmailChecked ? (
										<InputField label="Contact email address" value={email} disabled />
									) : (
										<InputField
											label="Contact email address"
											placeholder="e.g. contact@davescourses.com"
											value={contactEmail}
											onChange={setContactEmail}
										/>
									)}
									<FieldInfoText>
										The contact email be public to your users, and will also be used in your terms
										of service.
									</FieldInfoText>
								</div>
							</Inputs>

							<NavigationText>
								By signing up you agree to our{" "}
								<NavigationButton onClick="https://forento.io/policy/terms-of-service" newTab>
									Terms of Service
								</NavigationButton>
								.
							</NavigationText>

							<SubmitButton variant="primary" isLoading={isSubmitting}>
								Sign up
							</SubmitButton>

							<NavigationText>
								Already have an account?{" "}
								<NavigationButton onClick={routes.account.signin()}>Sign in</NavigationButton>
							</NavigationText>
						</Content>
					</>
				) : (
					<Text>That link is either invalid or already used.</Text>
				)}
			</Content>
		</Layout>
	)
}

const LogoContainer = styled.div`
	display: flex;
	gap: 32px;
	align-items: center;
	margin-bottom: 46px;
	font-weight: 600;
	font-size: 32px;
`

const StyledLogo = styled(Logo)`
	margin-bottom: 0;
`

const AppSumoLogo = styled.img.attrs({ src: appSumoLogo })`
	width: 150px;
`

const ErrorText = styled(Text)`
	color: ${dangerColor};
`

const Keys = styled.ol`
	padding-inline-start: 1em;
	display: flex;
	flex-direction: column;
	gap: 4px;
	margin-bottom: 8px;
`

const Key = styled.code``

const NewKeyContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
`

const NewKeyInput = styled(InputField).attrs({ compact: true })`
	flex: 1 0 0;
`

const NewKeyButton = styled(SubmitButton).attrs({ replaceOnLoading: true })`
	width: 24px;
	height: 24px;
`

const NewKeyButtonIcon = styled(AddIcon)`
	width: 100%;
	height: 100%;
`

const StyledCheckbox = styled(Checkbox)`
	margin-bottom: 8px;
`

export default AppSumoSignupPage
