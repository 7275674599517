import { type DragEndEvent } from "@dnd-kit/core"
import { arrayMove } from "@dnd-kit/sortable"
import { type FC, useState, useLayoutEffect } from "react"

import Button from "~/components/Button"
import DetailedList from "~/components/DetailedList"
import FeatureIntroduction from "~/components/FeatureIntroduction"
import Layout, { PageHeader, PageTitle } from "~/components/Layout"
import PartialLoadingPage from "~/components/PartialLoadingPage"
import SortableListContext from "~/contexts/SortableListContext"
import Session from "~/pages/sessions/SessionsPage/Session"
import routes from "~/utilities/routes"
import trpc, { swr } from "~/utilities/trpc"

import illustration from "./illustration.svg"

const SessionsPage: FC = () => {
	const { data: sessions, error, mutate } = swr.privateSession.list.useSWR()

	const [sortOrder, setSortOrder] = useState<number[]>([])

	useLayoutEffect(() => {
		setSortOrder(sessions?.map(session => session.id) ?? [])
	}, [sessions])

	function handleDragEnd(event: DragEndEvent) {
		if (!sessions) return
		const { active, over } = event
		if (!over || active.id === over.id) return

		const newItems = arrayMove(sortOrder, sortOrder.indexOf(Number(active.id)), sortOrder.indexOf(Number(over.id)))
		setSortOrder(newItems)

		for (const session of sessions) {
			trpc.privateSession.edit.mutate({ id: session.id, data: { sortOrder: newItems.indexOf(session.id) } })
		}
	}

	return (
		<Layout>
			<PageHeader>
				<PageTitle>Sessions</PageTitle>
				<Button variant="primary" onClick={routes.sessions.create()}>
					Create new
				</Button>
			</PageHeader>
			{error ? (
				<p>Failed to load sessions.</p>
			) : sessions === undefined ? (
				<PartialLoadingPage />
			) : sessions.length === 0 ? (
				<FeatureIntroduction
					illustrationUrl={illustration}
					title="Sessions"
					text="Enable your users to purchase sessions. These could be coaching sessions or other one-on-one meetings. How you perform them is up to you. It could for example be through a video call or in person."
				/>
			) : (
				<SortableListContext items={sortOrder} onDragEnd={handleDragEnd}>
					<DetailedList>
						{[...sessions]
							.sort((a, b) => sortOrder.indexOf(a.id) - sortOrder.indexOf(b.id))
							.map(session => (
								<Session key={session.id} session={session} reload={mutate} />
							))}
					</DetailedList>
				</SortableListContext>
			)}
		</Layout>
	)
}

export default SessionsPage
