import { type FC } from "react"
import styled, { css } from "styled-components"

import Button from "~/components/Button"
import { primaryColor, primaryLightColor } from "~/utilities/styles"

type Props = {
	channels: { id: number | null; name: string }[]
	selectedChannelId: number | null
	setSelectedChannelId: (id: number | null) => void
}
const ChannelSelector: FC<Props> = ({ channels, selectedChannelId, setSelectedChannelId }) => (
	<Container>
		{channels.map(({ id, name }, index) => (
			<Option key={index} isActive={id === selectedChannelId} onClick={() => setSelectedChannelId(id)}>
				{name}
			</Option>
		))}
	</Container>
)

const Container = styled.div`
	grid-area: channels;
	display: flex;
	background-color: ${primaryLightColor};
	padding: 6px;
	border-radius: 8px;
`

const Option = styled(Button)<{ isActive: boolean }>`
	font-weight: 600;
	text-decoration: none;
	border-radius: 6px;
	padding: 10px 14px;
	transition: 0.2s;
	display: flex;
	align-items: center;
	gap: 8px;

	${props =>
		props.isActive &&
		css`
			color: ${primaryColor};
			background-color: white;
			box-shadow:
				0 1px 3px rgba(16, 24, 40, 0.1),
				0 1px 2px rgba(16, 24, 40, 0.06);
		`}
`

export default ChannelSelector
