import type { OutputData } from "@editorjs/editorjs"
import { type FC, useCallback, useState, useEffect, useMemo, lazy, Suspense } from "react"
import { Navigate, useParams } from "react-router"

import { type ChapterDripInterval } from "@forento/shared/models/course"
import { toPriceString } from "@forento/shared/utilities/currency"
import { getDataUrlByFile } from "@forento/shared/utilities/file"
import { parseNumber } from "@forento/shared/utilities/number"

import Layout, { PageBreadcrumb } from "~/components/Layout"
import PartialLoadingPage from "~/components/PartialLoadingPage"
import ProductPricing from "~/components/ProductPricing"
import { ControlledTabs } from "~/components/Tabs"
import { useAlert } from "~/contexts/AlertContext"
import { useToast } from "~/contexts/ToastContext"
import { useUser } from "~/contexts/UserContext"
import usePrice from "~/hooks/usePrice"
import routes from "~/utilities/routes"
import trpc, { swr } from "~/utilities/trpc"

import ChapterDripping from "./ChapterDripping"
import CourseInformation, { type CourseInformationData } from "./CourseInformation"
import IssueCertificates from "./IssueCertificates"
import Testers from "./Testers"
import { Description, Title } from "./styles"

const CourseLongDescriptionEditor = lazy(() => import("./CourseLongDescriptionEditor"))

const CourseSettingsPage: FC = () => {
	const courseId = parseNumber(useParams().courseId) ?? -1
	const alert = useAlert()
	const toast = useToast()
	const platform = useUser().user!.platform!

	const course = swr.course.get.useSWR(courseId)
	const tags = swr.course.listTags.useSWR()

	const [informationData, setInformationData] = useState<CourseInformationData>()
	const [chapterDripInterval, setChapterDripInterval] = useState<ChapterDripInterval | null>(null)
	const [issueCertificates, setIssueCertificates] = useState(false)
	const { price, priceValue, setPrice } = usePrice()
	const [membershipIds, setMembershipIds] = useState<number[]>([])
	const [longDescription, setLongDescription] = useState<{ isModified: boolean; value: OutputData } | null>()
	const [isLoaded, setLoaded] = useState(false)

	useEffect(() => {
		if (course.data) {
			setInformationData({
				title: course.data.title,
				shortDescription: course.data.shortDescription,
				commentsEnabled: course.data.commentsEnabled,
				autoApproveComments: course.data.autoApproveComments,
				tags: course.data.tags.map(tag => tag.id),
			})
			setChapterDripInterval(course.data.chapterDripInterval)
			setIssueCertificates(course.data.issueCertificates)
			setLongDescription(course.data.longDescription !== null ? JSON.parse(course.data.longDescription) : null)
			setPrice(course.data.price?.amount.toString() ?? "")
			setMembershipIds(course.data.membershipIds)
			setLoaded(true)
		}
	}, [course.data, setPrice])

	useEffect(() => {
		const isModified =
			informationData &&
			course.data &&
			(informationData.title !== course.data.title ||
				informationData.shortDescription !== course.data.shortDescription ||
				informationData.commentsEnabled !== course.data.commentsEnabled ||
				informationData.autoApproveComments !== course.data.autoApproveComments ||
				informationData.tags.join(",") !== course.data.tags.map(tag => tag.id).join(",") ||
				chapterDripInterval !== course.data.chapterDripInterval ||
				issueCertificates !== course.data.issueCertificates ||
				longDescription?.isModified ||
				priceValue !== (course.data?.price ? course.data.price.amount : null) ||
				membershipIds.join(",") !== course.data.membershipIds.join(","))

		if (isModified) {
			toast.setUnsavedChanges(async () => {
				if (!course.data) return false
				if (!platform.paymentGateway?.isEnabled && priceValue !== null) {
					alert.show("Error", "You need to enable payments if you want to have paid courses.")
					return false
				}
				if (priceValue !== null && priceValue < 1) {
					alert.show(
						"Error",
						`The price must be at least ${toPriceString({ amount: 1, currency: platform.currency })}.`,
					)
					return false
				}

				try {
					await trpc.course.update.mutate({
						id: course.data.id,
						data: {
							title: informationData.title,
							shortDescription: informationData.shortDescription,
							price: priceValue,
							membershipIds,
							commentsEnabled: informationData.commentsEnabled,
							autoApproveComments: informationData.autoApproveComments,
							chapterDripInterval,
							issueCertificates,
							longDescription: longDescription ? JSON.stringify(longDescription.value) : null,
							tags: informationData.tags,
						},
					})
					await course.mutate()
					return true
				} catch (error) {
					console.error(error)
					await alert.show("Error", "Failed to save course.")
				}

				return false
			})
		} else {
			toast.clearUnsavedChanges()
		}
	}, [
		alert,
		chapterDripInterval,
		course,
		course.data,
		informationData,
		issueCertificates,
		longDescription,
		membershipIds,
		platform.currency,
		platform.paymentGateway?.isEnabled,
		priceValue,
		toast,
	])

	const initialLongDescription = useMemo(
		() => (course.data?.longDescription != null ? JSON.parse(course.data.longDescription) : null),
		[course.data?.longDescription],
	)

	const setCourseThumbnail = useCallback(
		async (thumbnailFile: File | null) => {
			if (thumbnailFile !== null) {
				await trpc.course.setThumbnail.mutate({ courseId, dataUrl: await getDataUrlByFile(thumbnailFile) })
			} else {
				await trpc.course.deleteThumbnail.mutate(courseId)
			}
		},
		[courseId],
	)

	if (course.data === null) return <Navigate to={routes.course.index()} />

	return (
		<Layout>
			<PageBreadcrumb title="Settings" path={[{ title: "Courses", link: routes.course.index() }]} />
			{course.error || tags.error ? (
				<p>Failed to load course.</p>
			) : !isLoaded || !course.data || !tags.data || !informationData || longDescription === undefined ? (
				<PartialLoadingPage />
			) : (
				<ControlledTabs
					tabs={[
						{
							title: "Course information",
							content: (
								<CourseInformation
									data={informationData}
									setData={setInformationData}
									tags={tags.data}
									reloadTags={tags.mutate}
									thumbnailFilePath={course.data.thumbnailFilePath}
									onThumbnailChange={setCourseThumbnail}
								/>
							),
						},
						{
							title: "Issue certificates",
							content: <IssueCertificates value={issueCertificates} onChange={setIssueCertificates} />,
						},
						{
							title: "Chapter dripping",
							content: (
								<ChapterDripping interval={chapterDripInterval} onChange={setChapterDripInterval} />
							),
						},
						{
							title: "Long description",
							content: (
								<Suspense fallback={<PartialLoadingPage />}>
									<CourseLongDescriptionEditor
										initialValue={initialLongDescription}
										setValue={setLongDescription}
									/>
								</Suspense>
							),
						},
						{
							title: "Price & availability",
							content: (
								<>
									<Title>Course access</Title>
									<Description>Select how people access your course, and set a price.</Description>
									<ProductPricing
										type="course"
										price={price}
										setPrice={setPrice}
										membershipIds={membershipIds}
										setMembershipIds={setMembershipIds}
									/>
								</>
							),
						},
						{
							title: "Testers",
							content: <Testers courseId={course.data.id} />,
						},
					]}
				/>
			)}
		</Layout>
	)
}

export default CourseSettingsPage
