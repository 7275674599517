import { type FC, lazy, Suspense, useEffect, useLayoutEffect, useMemo, useState } from "react"
import styled from "styled-components"

import { checkMjml } from "@forento/shared/utilities/mjml"
import { replacePlaceholders } from "@forento/shared/utilities/string"

import EmailPreview from "~/components/EmailPreview"
import Layout, { PageBreadcrumb } from "~/components/Layout"
import PartialLoadingPage from "~/components/PartialLoadingPage"
import RadioButtons from "~/components/RadioButtons"
import { useAlert } from "~/contexts/AlertContext"
import { useToast } from "~/contexts/ToastContext"
import { usePlatform } from "~/contexts/UserContext"
import routes from "~/utilities/routes"
import trpc, { swr } from "~/utilities/trpc"

const Editor = lazy(() => import("./Editor"))

const mjml2html = import("mjml-browser")

const options = [
	{ id: "default", label: "Use default content" },
	{ id: "custom", label: "Use custom content" },
] as const
type OptionId = (typeof options)[number]["id"]

const StudentEmailLayoutSettingsPage: FC = () => {
	const platform = usePlatform()!
	const alert = useAlert()
	const toast = useToast()

	const [selectedOptionId, setSelectedOptionId] = useState<OptionId>("default")
	const [customContent, setCustomContent] = useState("")

	const { data: layout, error, mutate } = swr.studentEmails.getLayout.useSWR()

	const layoutType: OptionId = layout?.isCustom ? "custom" : "default"

	const contentWithPlaceholders = useMemo(
		() =>
			layout
				? replacePlaceholders({
						text: selectedOptionId === "default" ? layout.content : customContent,
						placeholders: [
							{ key: "platformName", value: platform.name },
							{ key: "primaryColor", value: platform.primaryColor.value },
						],
					})
				: null,
		[customContent, layout, platform.name, platform.primaryColor.value, selectedOptionId],
	)

	useLayoutEffect(() => {
		setSelectedOptionId(layoutType)
		setCustomContent(layout?.content ?? "")
	}, [layout, layoutType])

	useEffect(() => {
		const isModified = layout && (selectedOptionId !== layoutType || customContent !== layout.content)

		if (isModified) {
			toast.setUnsavedChanges(async () => {
				if (selectedOptionId === "custom") {
					if (!contentWithPlaceholders) return false

					if (!customContent.includes("{content}")) {
						await alert.show(
							"Content not included",
							"The custom content must include the **{content}** placeholder where the email content should be put.",
						)
					}

					const mjmlStatus = checkMjml(contentWithPlaceholders, (await mjml2html).default)
					if (mjmlStatus.status === "invalid") {
						if (mjmlStatus.errors.length > 0) {
							await alert.show(
								"Invalid content",
								`The custom content is not a valid MJML.\n\n${mjmlStatus.errors.map(x => `- ${x}`).join("\n")}`,
							)
						} else {
							await alert.show("Invalid content", `The custom content is not a valid MJML.`)
						}
						return false
					}
				}
				try {
					await trpc.studentEmails.updateLayout.mutate(selectedOptionId === "custom" ? customContent : null)
					await mutate()
					return true
				} catch (error) {
					console.error(error)
					await alert.show("Failed to save", "Failed to save the student email layout. Please try again.")
					return false
				}
			})
		} else {
			toast.clearUnsavedChanges()
		}
	}, [alert, contentWithPlaceholders, customContent, layout, layoutType, mutate, selectedOptionId, toast])

	return (
		<Layout
			asideContent={
				contentWithPlaceholders && (
					<EmailPreview
						content={{
							type: "mjml",
							value: contentWithPlaceholders.replace(
								"{content}",
								"<mj-text>This is where the content will be placed.</mj-text>",
							),
						}}
					/>
				)
			}
		>
			<PageBreadcrumb
				title="Layout"
				path={[
					{ title: "Settings", link: routes.settings.index() },
					{ title: "Student Emails", link: routes.settings.studentEmail.index() },
				]}
			/>
			{error ? (
				<p>Failed to load student email layout.</p>
			) : !layout ? (
				<PartialLoadingPage />
			) : (
				<Content>
					<RadioButtons
						options={options.map(x => ({ value: x.id, label: x.label }))}
						value={selectedOptionId}
						onChange={value => setSelectedOptionId(options.find(x => x.id === value)?.id ?? "default")}
					/>
					{selectedOptionId === "custom" && (
						<Suspense fallback={<PartialLoadingPage />}>
							<Editor customContent={customContent} setCustomContent={setCustomContent} />
						</Suspense>
					)}
				</Content>
			)}
		</Layout>
	)
}

const Content = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 16px;
`

export default StudentEmailLayoutSettingsPage
