import { useState, type FC } from "react"
import styled, { css } from "styled-components"

import Tooltip from "@forento/shared/components/Tooltip"
import { type CommunityAccess } from "@forento/shared/models/community"
import { getCommunityAccessDisplayName } from "@forento/shared/utilities/community"

import Button from "~/components/Button"
import { CheckmarkIcon, CloseIcon, DeleteIcon, EditIcon } from "~/components/Icon"
import PartialLoadingPage from "~/components/PartialLoadingPage"
import Table from "~/components/Table"
import { useAlert } from "~/contexts/AlertContext"
import { dangerColor, successColor } from "~/utilities/styles"
import trpc, { swr } from "~/utilities/trpc"

import CreateModal from "./CreateModal"
import EditModal from "./EditModal"

const Channels: FC = () => {
	const alert = useAlert()

	const [isCreateModalOpen, setCreateModalOpen] = useState(false)
	const [editModal, setEditModal] = useState<{
		isOpen: boolean
		channel: {
			id: number | null
			name: string
			defaultAccess: CommunityAccess | null
			membershipAccess: Record<number, CommunityAccess>
		}
	}>({ isOpen: false, channel: { id: null, name: "", defaultAccess: null, membershipAccess: {} } })

	const { data, error, mutate } = swr.community.listChannels.useSWR()

	if (error) return <p>Failed to fetch channels.</p>

	if (data === undefined) return <PartialLoadingPage />

	async function handleDelete(id: number) {
		const channel = data?.find(item => item.id === id)
		if (channel === undefined) return

		const dialog = await alert.confirm(
			"Delete channel",
			`Are you sure you want to delete the channel **${channel.name}**? This will permanently **delete all posts in this channel**.`,
		)
		if (!dialog.result) return
		try {
			await trpc.community.deleteChannel.mutate(id)
			await mutate()
			dialog.close()
		} catch (error) {
			console.error(error)
			await alert.show("Error", "Failed to create the channel. Please try again.")
		}
	}

	return (
		<Container>
			<CreateModal isOpen={isCreateModalOpen} onClose={() => setCreateModalOpen(false)} onCreated={mutate} />
			<EditModal
				channel={editModal.channel}
				isOpen={editModal.isOpen}
				onClose={() => setEditModal(current => ({ ...current, isOpen: false }))}
				onSubmitted={mutate}
			/>
			<Table
				isCompact
				columns={["Name", "Default access", "Custom membership access", "Actions"]}
				data={data.map(item => [
					item.name,
					item.defaultAccess ? getCommunityAccessDisplayName(item.defaultAccess) : "None",
					Object.keys(item.membershipAccess).length > 0 ? (
						<Icon as={CheckmarkIcon} $variant="success" />
					) : (
						<Icon as={CloseIcon} $variant="danger" />
					),
					<Actions>
						<Tooltip tooltip="Edit">
							<Action onClick={() => setEditModal({ isOpen: true, channel: item })}>
								<ActionIcon as={EditIcon} />
							</Action>
						</Tooltip>
						{item.id !== null && (
							<Tooltip tooltip="Delete">
								<Action onClick={() => handleDelete(item.id!)}>
									<ActionIcon as={DeleteIcon} $isDanger />
								</Action>
							</Tooltip>
						)}
					</Actions>,
				])}
			/>
			<Button variant="primary" onClick={() => setCreateModalOpen(true)}>
				Create new channel
			</Button>
		</Container>
	)
}

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
	align-items: flex-end;
`

const Icon = styled.div<{ $variant: "success" | "danger" }>`
	width: 24px;
	height: 24px;
	color: ${props => (props.$variant === "success" ? successColor : dangerColor)};
`

const Actions = styled.div`
	display: flex;
	align-items: center;
	gap: 16px;
`

const Action = styled(Button)`
	width: 24px;
	height: 24px;
`

const ActionIcon = styled.div<{ $isDanger?: boolean }>`
	width: 100%;
	height: 100%;

	${props =>
		props.$isDanger &&
		css`
			color: ${dangerColor};
		`}
`

export default Channels
