import { type FC } from "react"
import styled from "styled-components"

import { type BlogPost } from "@forento/shared/models/dashboard"

import Button from "~/components/Button"

type Props = { title: string; slug: string; posts: BlogPost[] }
const BlogPosts: FC<Props> = ({ title, slug, posts }) => (
	<Container>
		<Title>{title}</Title>
		<List>
			{posts.map(post => (
				<Post key={post.link} newTab onClick={post.link}>
					{post.thumbnailFilePath ? (
						<PostThumbnail alt={`${post.title} thumbnail`} src={post.thumbnailFilePath} />
					) : (
						<PostThumbnailPlaceholder />
					)}
					<PostTitle>{post.title}</PostTitle>
					<PostContentContainer>
						<PostContent>{post.plainContent}</PostContent>
					</PostContentContainer>
				</Post>
			))}
			<SeeMore newTab onClick={`https://forento.io/blog/${slug}`}>
				See more posts
			</SeeMore>
		</List>
	</Container>
)

const Container = styled.div`
	border-radius: 12px;
	padding: 12px;
	display: flex;
	flex-direction: column;
	gap: 24px;
`

const Title = styled.h2`
	font-weight: 600;
	font-size: 16px;
`

const List = styled.div`
	display: flex;
	flex-direction: column;
	gap: 12px;
`

const Post = styled(Button)`
	font-weight: 500;
	font-size: 16px;
	display: grid;
	grid-template-areas: "thumbnail title" "thumbnail content";
	grid-template-columns: 75px 1fr;
	grid-template-rows: min-content min-content;
	gap: 0 12px;
`

const PostThumbnail = styled.img`
	grid-area: thumbnail;
	width: 100%;
	aspect-ratio: 1;
	object-fit: cover;
	border-radius: 8px;
`

const PostThumbnailPlaceholder = styled.div`
	grid-area: thumbnail;
	width: 100%;
	aspect-ratio: 1;
	background-color: #f0f0f0;
	border-radius: 8px;
`

const PostTitle = styled.h3`
	grid-area: title;
	font-size: 16px;
	font-weight: 500;
`

const PostContentContainer = styled.div`
	grid-area: content;
`

const PostContent = styled.p`
	color: #666;
	font-size: 14px;
	line-height: 1.5;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
`

const SeeMore = styled(Button)`
	align-self: center;
	font-size: 16px;
	font-weight: 500;
	color: #666;
`

export default BlogPosts
