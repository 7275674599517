import styled from "styled-components"

import Button from "~/components/Button"
import routes from "~/utilities/routes"

type Props = { label: string; value: number; unit?: string; trend: number }
const StatCard: React.FC<Props> = ({ label, value, unit, trend }) => (
	<Container onClick={routes.main.analytics()}>
		<Header>
			<Label>{label}</Label>
			<LinkIcon />
		</Header>
		<Content>
			<Value>
				{value}
				{unit !== undefined ? ` ${unit}` : ""}
			</Value>
			<Trend $direction={trend > 0 ? "up" : trend < 0 ? "down" : "flat"}>
				{Math.floor(trend * 100)}%
				<TrendIcon as={trend > 0 ? TrendUpIcon : trend < 0 ? TrendDownIcon : TrendFlatIcon} />
			</Trend>
			<TrendPeriod>vs previous 30 days</TrendPeriod>
		</Content>
	</Container>
)

const Container = styled(Button)`
	border-radius: 12px;
	padding: 12px;
	display: flex;
	flex-direction: column;
	gap: 24px;
`

const Header = styled.div`
	display: flex;
	justify-content: space-between;
	gap: 16px;
`

const Content = styled.div`
	display: flex;
	gap: 8px;
	align-items: center;
`

const Label = styled.p`
	font-weight: 500;
	font-size: 16px;
`

const LinkIcon: React.FC = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" height="24" width="24">
		<path d="M42 25.05V39Q42 40.2 41.1 41.1Q40.2 42 39 42H9Q7.8 42 6.9 41.1Q6 40.2 6 39V9Q6 7.8 6.9 6.9Q7.8 6 9 6H22.95V9H9Q9 9 9 9Q9 9 9 9V39Q9 39 9 39Q9 39 9 39H39Q39 39 39 39Q39 39 39 39V25.05ZM19.1 31.05 17 28.9 36.9 9H25.95V6H42V22.05H39V11.15Z" />
	</svg>
)

const TrendIcon = styled.div`
	width: 16px;
	height: 16px;
`

const TrendUpIcon: React.FC<{ className?: string }> = ({ className }) => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" fill="currentColor" className={className}>
		<path d="M6.15 36 4 33.85 18.6 19.3 26.95 27.65 39 15.6H32.65V12.6H44V23.95H41.05V17.8L26.9 31.95L18.55 23.6Z" />
	</svg>
)

const TrendFlatIcon: React.FC<{ className?: string }> = ({ className }) => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" fill="currentColor" className={className}>
		<path d="M35.1 32.95 32.95 30.85 38.25 25.55H6V22.55H38.3L33 17.25L35.1 15.15L44 24.05Z" />
	</svg>
)

const TrendDownIcon: React.FC<{ className?: string }> = ({ className }) => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" fill="currentColor" className={className}>
		<path d="M4 14.75 6.15 12.6 18.55 25 26.9 16.65 41.05 30.8V24.65H44V36H32.65V33H39L26.95 20.95L18.6 29.3Z" />
	</svg>
)

const Value = styled.p`
	font-weight: 700;
	font-size: 30px;
`

const Trend = styled.div<{ $direction: "up" | "flat" | "down" }>`
	background-color: white;
	font-weight: 600;
	font-size: 14px;
	color: ${props => (props.$direction === "up" ? "#00B75A" : props.$direction === "down" ? "#FA3934" : "black")};
	border-radius: 8px;
	padding: 4px 8px;
	display: flex;
	align-items: center;
	gap: 6px;
`

const TrendPeriod = styled.p`
	font-size: 14px;
`

export default StatCard
