import { communityAccess, type CommunityAccess } from "../models/community"

export function getCommunityAccessDisplayName(access: CommunityAccess): string {
	switch (access) {
		case "view":
			return "Only read"
		case "react":
			return "React to posts"
		case "comment":
			return "React to posts and comment"
		case "post":
			return "React to posts, comment, and post"
	}
}

type GetChannelAccessArgs = {
	channel: {
		id: number | null
		defaultAccess: CommunityAccess | null
		membershipAccess: Record<number, CommunityAccess>
	}
	user: { isCreator: boolean; membership: { id: number } | null }
}

export function getChannelAccess(args: GetChannelAccessArgs): CommunityAccess | null {
	if (args.user.isCreator) return "post"

	const membershipAccess = args.user.membership ? args.channel.membershipAccess[args.user.membership.id] : undefined
	return membershipAccess ?? args.channel.defaultAccess
}

export function isCommunityAccessHigher(access: CommunityAccess, compare: CommunityAccess | null): boolean {
	return communityAccess.indexOf(access) > (compare ? communityAccess.indexOf(compare) : -1)
}
