import { useSortable } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import { type FC, type CSSProperties } from "react"
import styled from "styled-components"

import { type ThinMembership } from "@forento/shared/models/membership"
import { toPriceString } from "@forento/shared/utilities/currency"

import Button from "~/components/Button"
import { DragIndicatorIcon, EditIcon, MoneyIcon } from "~/components/Icon"
import { useUser } from "~/contexts/UserContext"
import { primaryColor } from "~/utilities/styles"

type Props = { membership: ThinMembership; onEdit(): void }
const Membership: FC<Props> = ({ membership, onEdit }) => {
	const platform = useUser().user!.platform!
	const { setNodeRef, transform, transition, attributes, listeners } = useSortable({ id: membership.id })

	const style: CSSProperties = { transform: CSS.Transform.toString(transform), transition }

	return (
		<Container ref={setNodeRef} style={style}>
			<Header>
				<Title>{membership.title}</Title>
				<Actions>
					<Button onClick={onEdit}>
						<StyledEditIcon />
					</Button>
					<DragButton {...attributes} {...listeners}>
						<DragIndicatorIcon />
					</DragButton>
				</Actions>
			</Header>
			{membership.shortDescription && <p>{membership.shortDescription}</p>}
			{platform.plan.access.zapier && <p>Zapier membership ID: {membership.id}</p>}
			<PaymentOptions>
				{membership.prices.map(price => (
					<PaymentOption.Container key={price.id}>
						<PaymentOption.IconContainer>
							<PaymentOption.Icon />
						</PaymentOption.IconContainer>
						<PaymentOption.Value>
							{toPriceString(price)}
							<PaymentOption.Interval>/{price.paymentInterval}</PaymentOption.Interval>
						</PaymentOption.Value>
					</PaymentOption.Container>
				))}
			</PaymentOptions>
		</Container>
	)
}

const Container = styled.div`
	background-color: #fbfaf8;
	border: 1px solid #eeeeed;
	border-radius: 8px;
	padding: 24px;
	display: flex;
	flex-direction: column;
	gap: 8px;
`

const Header = styled.div`
	display: flex;
	gap: 16px;
	justify-content: space-between;
`

const StyledEditIcon = styled(EditIcon)`
	width: 24px;
	height: 24px;
	color: ${primaryColor};
`

const Title = styled.h2`
	font-weight: 600;
	font-size: 20px;
`

const Actions = styled.div`
	display: flex;
	gap: 8px;
`

const DragButton = styled.div`
	width: 24px;
	height: 24px;
	cursor: grab;
`

const PaymentOptions = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
`

const PaymentOption = {
	Container: styled.div`
		display: flex;
		gap: 8px;
	`,
	IconContainer: styled.div`
		width: 24px;
		height: 24px;
		background-color: ${primaryColor};
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
	`,
	Icon: styled(MoneyIcon)`
		width: 18px;
		height: 18px;
		color: #fbfaf8;
	`,
	Value: styled.p`
		font-weight: 600;
		font-size: 18px;
		letter-spacing: 0.75px;
	`,
	Interval: styled.span`
		font-size: 14px;
		margin-left: 8px;
	`,
}

export default Membership
