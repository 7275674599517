import { Navigate, useParams } from "react-router"
import styled from "styled-components"

import { parseNumber } from "@forento/shared/utilities/number"

import { ProfileCommunityPosts } from "~/components/CommunityPosts"
import Layout, { PageBreadcrumb } from "~/components/Layout"
import PartialLoadingPage from "~/components/PartialLoadingPage"
import withAccessRequirement from "~/hocs/withAccessRequirement"
import routes from "~/utilities/routes"
import { swr } from "~/utilities/trpc"

import Profile from "./Profile"
import coverImage from "./cover.png"

const CommunityProfilePage: React.FC = () => {
	const profileId = parseNumber(useParams().profileId ?? "") ?? -1

	const { data: profile, error } = swr.community.getProfile.useSWR(profileId)

	if (profile === null) return <Navigate replace to={routes.community.profiles()} />

	return (
		<Layout>
			<PageBreadcrumb
				path={[{ title: "Community Profiles", link: routes.community.profiles() }]}
				title={profile ? `${profile.firstName} ${profile.lastName}` : "..."}
			/>
			{error ? (
				<p>Failed to load profile.</p>
			) : !profile ? (
				<PartialLoadingPage />
			) : (
				<Content>
					<CoverImage src={coverImage} />
					<Profile profile={profile} />
					<ProfileCommunityPosts profileId={profile.id} gridArea="posts" />
				</Content>
			)}
		</Layout>
	)
}

const Content = styled.div`
	display: grid;
	grid-template-areas: "cover cover" "profile posts" ". posts";
	grid-template-columns: min-content 1fr;
	grid-template-rows: min-content min-content 1fr;
	gap: 24px;
`

const CoverImage = styled.img`
	grid-area: cover;
	width: 100%;
	border-radius: 12px;
`

export default withAccessRequirement(
	"community",
	<PageBreadcrumb path={[{ title: "Community Profiles", link: routes.community.profiles() }]} title="Profile" />,
	CommunityProfilePage,
)
