import { type FC } from "react"
import styled from "styled-components"

import Button from "~/components/Button"

const guides = [
	{ title: "🚀 Quick start guide", link: "https://forento.link/dashboard-guide-quick-start" },
	{ title: "🔗 Connecting a custom domain", link: "https://forento.link/dashboard-guide-custom-domain" },
	{ title: "🛒 Products on Forento", link: "https://forento.link/dashboard-guide-products" },
	{ title: "🖥️ Website setup guide", link: "https://forento.link/dashboard-guide-website" },
] satisfies { title: string; link: string }[]

const Guides: FC = () => (
	<Container>
		<Title>Guides</Title>
		<List>
			{guides.map(({ title, link }) => (
				<Guide key={title} newTab onClick={link}>
					{title}
				</Guide>
			))}
			<Guide newTab onClick="https://forento.link/dashboard-guide-all">
				📋 View all articles
			</Guide>
		</List>
	</Container>
)

const Container = styled.div`
	border-radius: 12px;
	padding: 12px;
	display: flex;
	flex-direction: column;
	gap: 24px;
`

const Title = styled.h2`
	font-weight: 600;
	font-size: 16px;
`

const List = styled.div`
	display: flex;
	flex-direction: column;
	gap: 12px;
`

const Guide = styled(Button)`
	font-weight: 500;
	font-size: 16px;
`

export default Guides
