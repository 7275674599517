import { type FC } from "react"
import styled from "styled-components"

import Tooltip from "@forento/shared/components/Tooltip"
import { toDateTimeString } from "@forento/shared/utilities/date"

import Button from "~/components/Button"
import { DeleteIcon as DeleteIconBase } from "~/components/Icon"
import Layout, { PageBreadcrumb } from "~/components/Layout"
import PartialLoadingPage from "~/components/PartialLoadingPage"
import Table from "~/components/Table"
import { useAlert } from "~/contexts/AlertContext"
import routes from "~/utilities/routes"
import { dangerColor } from "~/utilities/styles"
import trpc, { swr } from "~/utilities/trpc"

const BannedIpsSettingsPage: FC = () => {
	const alert = useAlert()

	const { data, error, mutate } = swr.platform.listBannedIps.useSWR()

	async function handleDelete(entry: { id: number; ip: string }) {
		const result = await alert.confirm(
			"Delete banned IP",
			`Are you sure you want to delete the banned IP **${entry.ip}**?`,
		)
		if (!result.result) return

		try {
			await trpc.platform.deleteBannedIp.mutate(entry.id)
			await mutate()
			result.close()
		} catch (error) {
			console.error(error)
			await alert.show("Error", "Failed to delete banned IP. Please try again later.")
		}
	}

	return (
		<Layout>
			<PageBreadcrumb title="Banned IPs" path={[{ title: "Settings", link: routes.settings.index() }]} />
			<Text>When deleting users you can choose to ban their IPs as well. These will appear here.</Text>
			{error ? (
				<p>Failed to load banned IPs.</p>
			) : data === undefined ? (
				<PartialLoadingPage />
			) : (
				<Table
					columns={["IP", "Ban date", "Actions"]}
					data={data.map(x => [
						x.ip,
						toDateTimeString(x.createDate),
						<Actions>
							<Tooltip tooltip="Delete">
								<DeleteButton onClick={() => handleDelete(x)}>
									<DeleteIcon />
								</DeleteButton>
							</Tooltip>
						</Actions>,
					])}
				/>
			)}
		</Layout>
	)
}

const Text = styled.p`
	margin-bottom: 24px;
`

const Actions = styled.div`
	display: flex;
`

const DeleteButton = styled(Button)`
	width: 24px;
	height: 24px;
`

const DeleteIcon = styled(DeleteIconBase)`
	width: 24px;
	height: 24px;
	color: ${dangerColor};
`

export default BannedIpsSettingsPage
