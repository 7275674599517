import styled from "styled-components"

import AspectRatio from "@forento/shared/components/AspectRatio"

import { primaryLightColor } from "~/utilities/styles"

interface PreviewProps {
	title: string
	description: string
	imageFileUrl: string
}

const Preview: React.FC<PreviewProps> = ({ title, description, imageFileUrl }) => (
	<Container>
		<Title>Preview</Title>
		<Text>Quick preview about how it looks on social media channels with the details you entered.</Text>

		<Frame>
			<Header>
				<HeaderButtons>
					<HeaderButton $color="#FF5353" />
					<HeaderButton $color="#EFDD3F" />
					<HeaderButton $color="#62EC91" />
				</HeaderButtons>
			</Header>
			<Content>
				<AspectRatio width={{ value: 100, unit: "percent" }} aspectRatio={2}>
					<PostThumbnail $imageFileUrl={imageFileUrl} />
				</AspectRatio>
				<PostTitle>{title}</PostTitle>
				<PostDescription>{description}</PostDescription>
			</Content>
		</Frame>
	</Container>
)

const Container = styled.div`
	flex: 0.5;
	background-color: ${primaryLightColor};
	padding: 34px 30px;
`

const Title = styled.h2`
	font-weight: 600;
	font-size: 24px;
	margin-bottom: 20px;
`

const Text = styled.p`
	margin-bottom: 28px;
`

const Frame = styled.div`
	background-color: white;
	border: 1px solid #eeeeee;
	box-shadow: 0 4px 19px rgba(0, 0, 0, 0.04);
	border-radius: 6px;
	overflow: hidden;
`

const Header = styled.div`
	background-color: #262626;
	padding: 8px 12px;
`

const HeaderButtons = styled.div`
	display: flex;
	gap: 6px;
`

const HeaderButton = styled.div<{ $color: string }>`
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background-color: ${props => props.$color};
`

const Content = styled.div`
	padding: 12px;
`

const PostThumbnail = styled.div<{ $imageFileUrl: string }>`
	background-image: url(${props => props.$imageFileUrl});
	background-size: cover;
	border-radius: 6px;
`

const PostTitle = styled.h3`
	font-weight: 600;
	font-size: 18px;
	color: #151515;
	margin-top: 12px;
	margin-bottom: 4px;
`

const PostDescription = styled.p`
	font-size: 13px;
`

export default Preview
