import { type FC, useState } from "react"

import Button, { SubmitButton } from "~/components/Button"
import InputField, { useRichTextArea } from "~/components/InputField"
import { DefaultModal, ModalButtons, ModalInputs, ModalTitle } from "~/components/Modal"
import trpc from "~/utilities/trpc"

interface Props {
	isOpen: boolean
	onClose(): void
	onCreated(id: number): void
}

const CreateNewModal: FC<Props> = ({ isOpen, onClose, onCreated }) => {
	const [name, setName] = useState("")

	const [shortDescription, setShortDescription] = useState("")
	const longDescription = useRichTextArea({ label: "Description" })
	const [isSubmitting, setSubmitting] = useState(false)

	const isInputValid = name.trim().length > 0

	const handleSubmit = async () => {
		if (!isInputValid) return

		setSubmitting(true)
		try {
			const created = await trpc.exam.create.mutate({
				name,
				shortDescription,
				longDescription: longDescription.exportEditorState(),
			})
			setName("")
			setShortDescription("")
			longDescription.clear()
			onCreated(created.id)
			onClose()
		} catch (error) {
			console.error(error)
		} finally {
			setSubmitting(false)
		}
	}

	return (
		<DefaultModal isOpen={isOpen} onSubmit={handleSubmit}>
			<ModalTitle>Create new exam</ModalTitle>
			<ModalInputs>
				<InputField label="Name" value={name} onChange={setName} />
				<InputField label="Short description" value={shortDescription} onChange={setShortDescription} />
				{longDescription.element}
			</ModalInputs>
			<ModalButtons>
				<Button variant="secondary" onClick={onClose} isDisabled={isSubmitting}>
					Cancel
				</Button>
				<SubmitButton variant="primary" isDisabled={!isInputValid} isLoading={isSubmitting}>
					Create exam
				</SubmitButton>
			</ModalButtons>
		</DefaultModal>
	)
}

export default CreateNewModal
