import { type FC } from "react"
import styled from "styled-components"

import Tooltip from "@forento/shared/components/Tooltip"
import { toDateTimeString } from "@forento/shared/utilities/date"

import Button from "~/components/Button"
import { DeleteIcon } from "~/components/Icon"
import Layout, { PageBreadcrumb, PageHeader } from "~/components/Layout"
import PartialLoadingPage from "~/components/PartialLoadingPage"
import Table from "~/components/Table"
import { useAlert } from "~/contexts/AlertContext"
import withAccessRequirement from "~/hocs/withAccessRequirement"
import routes from "~/utilities/routes"
import { dangerColor } from "~/utilities/styles"
import trpc, { swr } from "~/utilities/trpc"

const ZapierSettingsPage: FC = () => {
	const alert = useAlert()

	const { data, error, mutate } = swr.zapier.listKeys.useSWR()

	return (
		<Layout>
			<PageHeader>
				<PageBreadcrumb path={[{ title: "Settings", link: routes.settings.index() }]} title="Zapier" />
				<Button
					variant="primary"
					onClick={async () => {
						const key = await trpc.zapier.createKey.mutate()
						await alert.show(
							"New API key",
							`Your new API key has been created. Please copy it now as it will not be shown again. Your API key:\n\n\`${key.apiKey}\``,
						)
						await mutate()
					}}
				>
					Create new API key
				</Button>
			</PageHeader>
			<Description>
				Connect Zapier to your academy to grant users access to products or memberships externally.
			</Description>
			<Instructions>
				<li>
					<a
						href="https://zapier.com/developer/public-invite/194814/2f80ff82e776fc8ac4ae308e7c8a2895/"
						target="_blank"
						rel="noopener noreferrer"
					>
						Install Zapier app
					</a>
				</li>
				<li>Create new API key</li>
				<li>Enter API key into the Zapier app</li>
			</Instructions>
			<SectionTitle>API keys</SectionTitle>
			{error ? (
				<p>Failed to load API keys</p>
			) : !data ? (
				<PartialLoadingPage />
			) : (
				<Table
					columns={["Create date", "Last used", "Actions"]}
					data={data.map(item => [
						toDateTimeString(item.createDate),
						item.lastUsedDate ? toDateTimeString(item.lastUsedDate) : "Never",
						<Actions>
							<Tooltip tooltip="Delete">
								<Button
									onClick={async () => {
										const dialog = await alert.confirm(
											"Delete API key",
											"Are you sure you want to delete this API key? It will stop working if any integrations are using it.",
										)
										if (!dialog.result) return
										await trpc.zapier.deleteKey.mutate(item.id)
										await mutate()
										dialog.close()
									}}
								>
									<StyledDeleteIcon />
								</Button>
							</Tooltip>
						</Actions>,
					])}
				/>
			)}
		</Layout>
	)
}

const Description = styled.p`
	margin-bottom: 1em;
`

const Instructions = styled.ol`
	margin-bottom: 24px;
	margin-left: 1em;
`

const SectionTitle = styled.h2`
	font-weight: 600;
	font-size: 14px;
	margin-bottom: 6px;
`

const Actions = styled.div`
	display: flex;
`

const StyledDeleteIcon = styled(DeleteIcon)`
	width: 24px;
	height: 24px;
	color: ${dangerColor};
`

export default withAccessRequirement(
	"zapier",
	<PageBreadcrumb path={[{ title: "Settings", link: routes.settings.index() }]} title="Zapier" />,
	ZapierSettingsPage,
)
