export const primaryColor = "#5120DD"
export const primaryLightColor = "#F8F6F3"

export const lightTextColor = "#848382"

export const successColor = "#00B75A"
export const dangerColor = "#FA3934"

export const pageContentWidth = 1200

export const cardColor = {
	blue: "#E5F6FF",
	orange: "#FFF0DD",
	purple: "#F9F5FF",
	gray: "#F4F4F4",
}
