import { type FC } from "react"
import styled from "styled-components"

import Layout, { PageTitle } from "~/components/Layout"
import PartialLoadingPage from "~/components/PartialLoadingPage"
import { useUser } from "~/contexts/UserContext"
import BlogPosts from "~/pages/main/DashboardPage/BlogPosts"
import Guides from "~/pages/main/DashboardPage/Guides"
import { cardColor } from "~/utilities/styles"
import { swr } from "~/utilities/trpc"

import Onboarding from "./Onboarding"
import StatCard from "./StatCard"

const DashboardPage: FC = () => {
	const user = useUser().user!

	const { data, error } = swr.dashboard.get.useSWR()

	return (
		<Layout asideContent={data?.onboarding.some(x => !x.isCompleted) && <Onboarding tasks={data.onboarding} />}>
			<PageTitle>Dashboard</PageTitle>
			<Greeting>Welcome back, {user.firstName}</Greeting>
			{error ? (
				<p>Failed to load dashboard data.</p>
			) : data === undefined ? (
				<PartialLoadingPage />
			) : (
				<Cards>
					<StatCard
						label="Sales (last 30 days)"
						value={data.sales.value}
						unit={data.sales.currency}
						trend={data.sales.differenceFromLast}
					/>
					{data.visits && (
						<StatCard
							label="Visits (last 30 days)"
							value={data.visits.value}
							trend={data.visits.differenceFromLast}
						/>
					)}
					<Guides />
					<BlogPosts title="Product updates" slug="product-updates" posts={data.productUpdates} />
					<BlogPosts title="Creator highlights" slug="creator-highlights" posts={data.creatorHighlights} />
				</Cards>
			)}
		</Layout>
	)
}

const Greeting = styled.p`
	font-size: 28px;
	font-weight: bold;
	margin-bottom: 16px;
`

const Cards = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
	gap: 16px;

	& > :nth-child(4n + 1) {
		background-color: ${cardColor.blue};
	}
	& > :nth-child(4n + 2) {
		background-color: ${cardColor.orange};
	}
	& > :nth-child(4n + 3) {
		background-color: ${cardColor.purple};
	}
	& > :nth-child(4n + 4) {
		background-color: ${cardColor.gray};
	}
`

export default DashboardPage
