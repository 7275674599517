import { useEffect, useState } from "react"
import styled from "styled-components"

import { replaceArrayValue } from "@forento/shared/utilities/array"

import Button from "~/components/Button"
import { AddIcon } from "~/components/Icon"
import InputField, { useRichTextArea } from "~/components/InputField"

import Attachments from "../Attachments"
import Image from "../Image"
import Links from "../Links"
import { ButtonsGroup, ButtonsRow } from "../styles"
import QuizAnswer from "./QuizAnswer"

const defaultQuizAnswers = [{ text: "", isCorrect: true }]

type Props = {
	page: {
		id: number
		title: string
		text: string | null
		image: { filePath: string; contain: boolean } | null
		attachments: { id: number; fileType: string; label: string; url: string }[]
		links: { id: number; label: string; url: string }[]
		answers: { text: string; isCorrect: boolean }[] | null
	}
	onClose(): void
	onSavePage: (data: {
		title: string
		text: string | null
		containImage: boolean
		answers: { text: string; isCorrect: boolean }[]
	}) => Promise<void>
	onDeletePage(): void
}
const EditQuizPageModalContent: React.FC<Props> = ({ page, onClose, onSavePage, onDeletePage }) => {
	const [title, setTitle] = useState(page.title)
	const text = useRichTextArea({ label: "Text", initialValue: page.text, features: { headings: true, colors: true } })
	const [containImage, setContainImage] = useState(page.image?.contain ?? false)
	const [answers, setAnswers] = useState<{ text: string; isCorrect: boolean }[]>(
		page.answers?.map(x => ({ text: x.text, isCorrect: x.isCorrect })) ?? defaultQuizAnswers,
	)

	useEffect(() => {
		setTitle(page.title)
		setContainImage(page.image?.contain ?? false)
		setAnswers(page.answers?.map(x => ({ text: x.text, isCorrect: x.isCorrect })) ?? defaultQuizAnswers)
	}, [page])

	return (
		<>
			<InputField label="Title" value={title} onChange={setTitle} />
			{text.element}
			{answers.length > 0 && (
				<QuizAnswers>
					{answers.map((answer, index) => (
						<QuizAnswer
							key={index}
							text={answer.text}
							isCorrect={answer.isCorrect}
							onTextChange={value =>
								setAnswers(current =>
									replaceArrayValue(current, index, { ...current[index], text: value }),
								)
							}
							onIsCorrectChange={value => {
								setAnswers(current =>
									replaceArrayValue(current, index, { ...current[index], isCorrect: value }),
								)
							}}
							onDelete={() =>
								setAnswers(current => [...current.slice(0, index), ...current.slice(index + 1)])
							}
						/>
					))}
				</QuizAnswers>
			)}
			<AddQuizAnswerButton onClick={() => setAnswers(current => [...current, { text: "", isCorrect: false }])}>
				<AddQuizAnswerIcon /> Add a new quiz answer
			</AddQuizAnswerButton>
			<Image
				pageId={page.id}
				filePath={page.image?.filePath ?? null}
				containImage={containImage}
				setContainImage={setContainImage}
			/>
			<Attachments pageId={page.id} attachments={page.attachments} />
			<Links pageId={page.id} links={page.links} />
			<ButtonsRow>
				<ButtonsGroup>
					<Button variant="secondary" onClick={onClose}>
						Cancel
					</Button>
					<Button
						variant="primary"
						isDisabled={
							title.trim().length === 0 ||
							answers.length === 0 ||
							answers.every(x => !x.isCorrect) ||
							answers.some(x => x.text.trim().length === 0)
						}
						onClick={() => onSavePage({ title, text: text.exportEditorState(), containImage, answers })}
					>
						Save
					</Button>
				</ButtonsGroup>
				<Button variant="primary-danger" onClick={onDeletePage}>
					Delete
				</Button>
			</ButtonsRow>
		</>
	)
}

const QuizAnswers = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
	margin-bottom: 16px;
`

const AddQuizAnswerButton = styled(Button)`
	display: flex;
	justify-content: center;
	align-items: center;
`

const AddQuizAnswerIcon = styled(AddIcon)`
	width: 24px;
	height: 24px;
	margin-right: 8px;
`

export default EditQuizPageModalContent
