import { useSortable } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import { type FC } from "react"
import styled, { css } from "styled-components"

import Tooltip from "@forento/shared/components/Tooltip"
import routes from "@forento/shared/routes"

import Button from "~/components/Button"
import {
	CommentIcon,
	DeleteIcon,
	DragIndicatorIcon,
	EditIcon,
	HideIcon,
	InputIcon,
	LinkIcon,
	PreviewIcon,
	SettingsIcon,
	UsersIcon,
} from "~/components/Icon"
import { useAlert } from "~/contexts/AlertContext"
import { useUser } from "~/contexts/UserContext"
import { dangerColor } from "~/utilities/styles"
import trpc from "~/utilities/trpc"

type Props = {
	course: {
		id: number
		title: string
		publishDate: Date
		sales: number
		enrolledStudents: number
		totalCommentsUnderReview: number
	}
	reload(): Promise<unknown>
}
const PublishedCourse: FC<Props> = ({ course, reload }) => {
	const user = useUser()
	const alert = useAlert()

	const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: course.id })

	const style = { transform: CSS.Transform.toString(transform), transition }

	async function handlePreview() {
		const platformPath = routes.platform.course.player(course.id)
		const { url } = await trpc.user.createLoginToken.mutate({ platformPath })
		window.open(url, "_blank")
	}

	async function handleUnpublish() {
		const result = await alert.confirm(
			"Unpublish course",
			`Are you sure you want to unpublish the course **${course.title}**?`,
		)
		if (!result.result) return
		await trpc.course.unpublish.mutate(course.id)
		await reload()
		result.close()
	}

	async function handleDelete() {
		const result = await alert.confirm(
			"Delete course",
			`Are you sure you want to delete the course **${course.title}**? This action is irreversible.`,
		)
		if (!result.result) return
		await trpc.course.deleteById.mutate(course.id)
		await reload()
		result.close()
	}

	return (
		<ListItem ref={setNodeRef} style={style}>
			<div>
				<Button onClick={routes.app.course.detail(course.id)}>
					<Title>{course.title}</Title>
				</Button>
				<Subtitle>Published {course.publishDate.toLocaleDateString()}</Subtitle>
			</div>
			<Actions>
				{course.totalCommentsUnderReview > 0 && (
					<Tooltip tooltip="Review comments">
						<ActionButton onClick={routes.app.course.reviewComments(course.id)}>
							<NewCommentIndicator />
							<ActionButtonIcon as={CommentIcon} />
						</ActionButton>
					</Tooltip>
				)}
				<Tooltip tooltip="Enrolled students">
					<ActionButton onClick={routes.app.course.enrolledStudents(course.id)}>
						<ActionButtonIcon as={UsersIcon} />
						{course.enrolledStudents}
					</ActionButton>
				</Tooltip>
				<Tooltip tooltip="Edit">
					<ActionButton onClick={routes.app.course.detail(course.id)}>
						<ActionButtonIcon as={EditIcon} />
					</ActionButton>
				</Tooltip>
				<Tooltip tooltip="Inputs">
					<ActionButton onClick={routes.app.course.inputs(course.id)}>
						<ActionButtonIcon as={InputIcon} />
					</ActionButton>
				</Tooltip>
				<Tooltip tooltip="Settings">
					<ActionButton onClick={routes.app.course.settings(course.id)}>
						<ActionButtonIcon as={SettingsIcon} />
					</ActionButton>
				</Tooltip>
				<Tooltip tooltip="Open link">
					<ActionButton
						onClick={`//${user.user!.platform!.domain.platform}${routes.platform.course.detail(course.id)}`}
						newTab
					>
						<ActionButtonIcon as={LinkIcon} />
					</ActionButton>
				</Tooltip>
				<Tooltip tooltip="Preview">
					<ActionButton onClick={handlePreview} newTab>
						<ActionButtonIcon as={PreviewIcon} />
					</ActionButton>
				</Tooltip>
				<Tooltip tooltip={course.sales > 0 ? "You can't unpublish a course that has sales" : "Unpublish"}>
					<ActionButton isDisabled={course.sales > 0} onClick={handleUnpublish}>
						<ActionButtonIcon as={HideIcon} />
					</ActionButton>
				</Tooltip>
				<Tooltip tooltip={course.sales > 0 ? "You can't delete a course that has sales" : "Delete"}>
					<ActionButton isDanger isDisabled={course.sales > 0} onClick={handleDelete}>
						<ActionButtonIcon as={DeleteIcon} />
					</ActionButton>
				</Tooltip>
				<DragButton {...attributes} {...listeners}>
					<ActionButtonIcon as={DragIndicatorIcon} />
				</DragButton>
			</Actions>
		</ListItem>
	)
}

const ListItem = styled.li`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 24px 0;

	&:not(:last-child) {
		border-bottom: 1px solid #d6d6d6;
	}
`

const Actions = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	gap: 16px;
`

const Title = styled.h2`
	font-weight: 600;
	font-size: 16px;
	margin-bottom: 4px;
`

const Subtitle = styled.p`
	font-size: 14px;
	color: #6f6f6f;
`

const ActionButton = styled(Button)<{ isDanger?: boolean }>`
	height: 24px;
	display: flex;
	gap: 8px;

	${props =>
		props.isDanger &&
		!props.isDisabled &&
		css`
			color: ${dangerColor};
		`}
`

const ActionButtonIcon = styled.div`
	width: 24px;
	height: 24px;
`

const DragButton = styled.div`
	width: 24px;
	height: 24px;
	cursor: grab;
`

const NewCommentIndicator = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background-color: red;
`

export default PublishedCourse
