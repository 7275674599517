import { useState } from "react"
import styled from "styled-components"

import Button from "~/components/Button"
import PartialLoadingPage from "~/components/PartialLoadingPage"
import { swr } from "~/utilities/trpc"

import Conversation from "./Conversation"
import NewConversationModal from "./NewConversationModal"

const Messages: React.FC = () => {
	const [isNewConversationModalOpen, setNewConversationModalOpen] = useState(false)

	const { data: conversations, error } = swr.community.listConversations.useSWR()

	if (error) return <p>Failed to load conversations.</p>
	if (!conversations) return <PartialLoadingPage />
	return (
		<Container>
			<NewConversationModal
				isOpen={isNewConversationModalOpen}
				onClose={() => setNewConversationModalOpen(false)}
			/>
			<Button variant="primary" onClick={() => setNewConversationModalOpen(true)}>
				New message
			</Button>
			<Conversations>
				{[...conversations]
					.sort((a, b) => b.lastMessage.sendDate.getTime() - a.lastMessage.sendDate.getTime())
					.map(conversation => (
						<Conversation key={conversation.otherProfile.id} conversation={conversation} />
					))}
			</Conversations>
		</Container>
	)
}

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	gap: 16px;
`

const Conversations = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 16px;
`

export default Messages
