import { type FC } from "react"
import styled from "styled-components"

import Button from "~/components/Button"
import InputLabel from "~/components/InputLabel"
import { useAlert } from "~/contexts/AlertContext"
import { useUser } from "~/contexts/UserContext"
import trpc from "~/utilities/trpc"

import GamificationLevels from "./GamificationLevels"

const CommunitySettings: FC = () => {
	const user = useUser()
	const alert = useAlert()

	async function handleDeleteCommunity() {
		const dialog = await alert.confirm("Delete community", "Are you sure you want to delete the community?")
		if (!dialog.result) return
		await trpc.platform.update.mutate({ isCommunityEnabled: false })
		await user.reload()
		dialog.close()
	}

	return (
		<Container>
			<GamificationLevels />
			<div>
				<InputLabel>Danger zone</InputLabel>
				<Button variant="primary-danger" onClick={handleDeleteCommunity}>
					Delete community
				</Button>
			</div>
		</Container>
	)
}

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 16px;
`

export default CommunitySettings
