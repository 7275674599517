import { useState, type FC } from "react"
import { Link } from "react-router"
import styled from "styled-components"

import Button, { SubmitButton } from "~/components/Button"
import Checkbox from "~/components/Checkbox"
import { DefaultModal, ModalButtons, ModalInputs, ModalTexts, ModalTitle } from "~/components/Modal"
import { useAlert } from "~/contexts/AlertContext"
import routes from "~/utilities/routes"
import trpc from "~/utilities/trpc"

type Props = {
	isOpen: boolean
	user: { id: number; hasMembership: boolean; hasPurchasedContent: boolean }
	onClose(): void
	onDeleted(): Promise<unknown>
}
const DeleteModal: FC<Props> = ({ isOpen, user, onClose, onDeleted }) => {
	const alert = useAlert()

	const [banIp, setBanIp] = useState(false)
	const [isSubmitting, setSubmitting] = useState(false)

	async function handleSubmit() {
		setSubmitting(true)
		try {
			await trpc.user.deleteStudent.mutate({ userId: user.id, banIp })
			await onDeleted()
			onClose()
		} catch (error) {
			console.error(error)
			await alert.show("Error", "Failed to delete user. Please try again later.")
		} finally {
			setSubmitting(false)
		}
	}

	return (
		<DefaultModal isOpen={isOpen} onSubmit={handleSubmit}>
			<ModalTitle>Delete user</ModalTitle>
			<ModalTexts>
				<p>Are you sure you want to delete this user?</p>
				{user.hasMembership && <strong>- This user has an active membership</strong>}
				{user.hasPurchasedContent && <strong>- This user has purchased content</strong>}
			</ModalTexts>
			<ModalInputs>
				<div>
					<Checkbox label="Also ban the user's IP address" isChecked={banIp} onChange={setBanIp} />
					<CheckboxWarning>
						Ban IPs with caution. Everyone on the user's network will be unable to access your academy. If
						the user is behind a VPN, all users using that VPN may be blocked. You can find a list of banned
						IPs in <Link to={routes.settings.bannedIp()}>settings</Link>.
					</CheckboxWarning>
				</div>
			</ModalInputs>
			<ModalButtons>
				<Button variant="secondary" onClick={onClose} isDisabled={isSubmitting}>
					Cancel
				</Button>
				<SubmitButton variant="primary-danger" isLoading={isSubmitting}>
					Delete
				</SubmitButton>
			</ModalButtons>
		</DefaultModal>
	)
}

const CheckboxWarning = styled.p`
	margin-top: 6px;
	font-size: 14px;
	color: #757575;
`

export default DeleteModal
