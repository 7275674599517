import { type FC } from "react"
import styled from "styled-components"

import { cardColor } from "~/utilities/styles"

const StatCard: FC<{ values: { unit?: string; value: number }[]; label: string }> = ({ values, label }) => (
	<Container>
		<Label>{label}</Label>
		<Values>
			{values.map(({ value, unit }, index) => (
				<Value key={index}>
					{value}
					{unit !== undefined ? ` ${unit}` : ""}
				</Value>
			))}
		</Values>
	</Container>
)

const Container = styled.div`
	flex: 0 0 400px;
	background-color: ${cardColor.blue};
	padding: 32px;
	border-radius: 12px;
	display: flex;
	flex-direction: column;
	gap: 24px;
`

const Label = styled.h2`
	font-size: 16px;
	color: black;
`

const Values = styled.div`
	display: flex;
	flex-direction: column;
	gap: 4px;
`

const Value = styled.div`
	font-weight: 700;
	font-size: 30px;
	color: black;
`

export default StatCard
