import { type FC, useEffect, useLayoutEffect, useMemo, useState } from "react"
import { Navigate, useNavigate, useParams } from "react-router"
import styled from "styled-components"

import Tooltip from "@forento/shared/components/Tooltip"
import { type VideoTag, type VideoWithCaptions, type VideoCaptionLanguageCode } from "@forento/shared/models/video"
import { toggleStateArray } from "@forento/shared/utilities/array"
import { getDataUrlByFile, getFileNameFromPath } from "@forento/shared/utilities/file"
import { parseNumber } from "@forento/shared/utilities/number"

import Button from "~/components/Button"
import { SettingsIcon } from "~/components/Icon"
import ImageUploader from "~/components/ImageUploader"
import InputField, { useRichTextArea } from "~/components/InputField"
import InputLabel from "~/components/InputLabel"
import Layout, { PageBreadcrumb, PageHeader } from "~/components/Layout"
import ManageTagsModal from "~/components/ManageTagsModal"
import PartialLoadingPage from "~/components/PartialLoadingPage"
import ProductPricing from "~/components/ProductPricing"
import SelectTags from "~/components/SelectTags"
import VideoCaptions from "~/components/VideoCaptions"
import { useAlert } from "~/contexts/AlertContext"
import { useToast } from "~/contexts/ToastContext"
import usePrice from "~/hooks/usePrice"
import { arrayBufferToBase64 } from "~/utilities/buffer"
import routes from "~/utilities/routes"
import trpc, { swr } from "~/utilities/trpc"

const EditVideoPage: FC = () => {
	const videoId = parseNumber(useParams().videoId) ?? -1
	const alert = useAlert()
	const navigate = useNavigate()

	const video = swr.video.get.useSWR({ videoId })
	const tags = swr.video.listTags.useSWR()
	const memberships = swr.membership.list.useSWR()

	if (video.data === null) return <Navigate to={routes.video.index()} />

	async function handleDelete() {
		const dialog = await alert.confirm(
			"Delete video",
			"Are you sure you want to delete this video? This action is irreversible.",
		)
		if (!dialog.result) return

		try {
			const { status } = await trpc.video.delete.mutate({ videoId })
			if (status !== "success") {
				if (status === "is-purchased") {
					await alert.show("Error", "You cannot delete a video that is purchased by a customer.")
				}
				return
			}
			dialog.close()
			navigate(routes.video.index())
		} catch (error) {
			console.error(error)
			await alert.show("Error", "Failed to delete video. Please try again later.")
		}
	}

	return (
		<Layout>
			<PageHeader>
				<PageBreadcrumb title="Edit" path={[{ title: "Videos", link: routes.video.index() }]} />
				<Button variant="primary-danger" onClick={handleDelete}>
					Delete
				</Button>
			</PageHeader>

			{video.error || tags.error || memberships.error ? (
				<p>Failed to load data.</p>
			) : !video.data || !tags.data || !memberships.data ? (
				<PartialLoadingPage />
			) : (
				<Content video={video.data} tags={tags.data} reloadVideo={video.mutate} reloadTags={tags.mutate} />
			)}
		</Layout>
	)
}

type ContentProps = {
	video: VideoWithCaptions
	tags: VideoTag[]
	reloadVideo(): Promise<unknown>
	reloadTags(): Promise<unknown>
}
const Content: FC<ContentProps> = ({ video, tags, reloadVideo, reloadTags }) => {
	const alert = useAlert()
	const toast = useToast()

	const [title, setTitle] = useState(video.title)
	const [shortDescription, setShortDescription] = useState(video.shortDescription ?? "")
	const longDescription = useRichTextArea({ label: "Description", initialValue: video.longDescription })
	const [thumbnail, setThumbnail] = useState<File | null>(null)
	const [isThumbnailUpdated, setThumbnailUpdated] = useState(false)
	const [isSignInRequired, setSignInRequired] = useState(video.isSignInRequired)
	const { price, priceValue, setPrice } = usePrice(video.price?.amount)
	const [membershipIds, setMembershipIds] = useState(video.membershipIds)
	const [selectedTags, setSelectedTags] = useState(video.tags.map(x => x.id))
	const [addCaptions, setAddCaptions] = useState<{ language: VideoCaptionLanguageCode; file: File }[]>([])
	const [removeCaptions, setRemoveCaptions] = useState<VideoCaptionLanguageCode[]>([])

	const [isManageTagsModalOpen, setManageTagsModalOpen] = useState(false)

	useLayoutEffect(() => {
		const abortController = new AbortController()

		setTitle(video.title)
		setShortDescription(video.shortDescription ?? "")
		setSignInRequired(video.isSignInRequired)
		setPrice(video.price?.amount.toString() ?? "")
		setMembershipIds(video.membershipIds)
		setSelectedTags(video.tags.map(x => x.id))
		setAddCaptions([])
		setRemoveCaptions([])

		if (video.host === "bunny" && video.thumbnailFilePath) {
			fetch(video.thumbnailFilePath, { signal: abortController.signal })
				.then(async x => ({ name: getFileNameFromPath(x.url), blob: await x.blob() }))
				.then(({ name, blob }) => {
					if (abortController.signal.aborted) return
					setThumbnail(new File([blob], name ?? "Thumbnail", { type: blob.type }))
				})
		}

		return () => {
			abortController.abort()
		}
	}, [setPrice, video])

	const thumbnailFilePath = useMemo(() => (thumbnail ? URL.createObjectURL(thumbnail) : null), [thumbnail])

	useEffect(() => {
		const isModified =
			title !== video.title ||
			shortDescription !== (video.shortDescription ?? "") ||
			longDescription.isModified ||
			isThumbnailUpdated ||
			isSignInRequired !== video.isSignInRequired ||
			priceValue !== (video.price?.amount ?? null) ||
			selectedTags.join(",") !== video.tags.map(x => x.id).join(",") ||
			addCaptions.length > 0 ||
			removeCaptions.length > 0

		if (isModified) {
			toast.setUnsavedChanges(async () => {
				if (title.trim().length === 0) return false

				try {
					await trpc.video.update.mutate({
						videoId: video.id,
						data: {
							title,
							shortDescription,
							longDescription: longDescription.exportEditorState(),
							thumbnailDataUrl: isThumbnailUpdated
								? thumbnail
									? await getDataUrlByFile(thumbnail)
									: null
								: undefined,
							isSignInRequired,
							price: priceValue,
							membershipIds,
							tags: selectedTags,
							removeCaptions,
							addCaptions: await Promise.all(
								addCaptions.map(async caption => ({
									language: caption.language,
									base64: arrayBufferToBase64(await caption.file.arrayBuffer()),
								})),
							),
						},
					})
					await reloadVideo()
					setThumbnailUpdated(false)
					return true
				} catch (error) {
					console.error(error)
					await alert.show("Error", "Failed to save changes. Please try again later.")
					return false
				}
			})
		} else {
			toast.clearUnsavedChanges()
		}
	}, [
		addCaptions,
		alert,
		isSignInRequired,
		longDescription,
		thumbnail,
		isThumbnailUpdated,
		membershipIds,
		priceValue,
		reloadVideo,
		removeCaptions,
		selectedTags,
		shortDescription,
		title,
		toast,
		video,
	])

	return (
		<Container>
			<ManageTagsModal
				type="video"
				isOpen={isManageTagsModalOpen}
				tags={tags}
				onClose={() => setManageTagsModalOpen(false)}
				onChange={reloadTags}
			/>

			<Section>
				<Subtitle>Basic information</Subtitle>
				<InputField placeholder="Title" value={title} onChange={setTitle} />
				<InputField
					label="Short description"
					placeholder="Short description"
					value={shortDescription}
					onChange={setShortDescription}
				/>
				{longDescription.element}
				<div>
					<InputLabel>Thumbnail</InputLabel>
					<ImageUploader
						filePath={thumbnailFilePath}
						onUpload={file => {
							setThumbnail(file)
							setThumbnailUpdated(true)
						}}
						onDelete={() => {
							setThumbnail(null)
							setThumbnailUpdated(true)
						}}
					/>
				</div>
			</Section>

			<Section>
				<Subtitle>Pricing</Subtitle>
				<ProductPricing
					type="video"
					isSignInRequired={isSignInRequired}
					setSignInRequired={setSignInRequired}
					price={price}
					setPrice={setPrice}
					membershipIds={membershipIds}
					setMembershipIds={setMembershipIds}
				/>
			</Section>

			<Section>
				<div>
					<Header>
						<Subtitle>Tags</Subtitle>
						<Tooltip tooltip="Manage tags">
							<TagSettingsButton onClick={() => setManageTagsModalOpen(true)}>
								<SettingsIcon />
							</TagSettingsButton>
						</Tooltip>
					</Header>
					<Description>Tags help students find your video when they search for specific topics.</Description>
				</div>
				<SelectTags
					tags={tags}
					selectedTags={selectedTags}
					onChange={tagId => toggleStateArray(setSelectedTags, tagId)}
				/>
			</Section>

			<Section>
				<Subtitle>Captions</Subtitle>
				{video.captions ? (
					<VideoCaptions
						captions={video.captions}
						addCaptions={addCaptions}
						removeCaptions={removeCaptions}
						setAddCaptions={setAddCaptions}
						setRemoveCaptions={setRemoveCaptions}
					/>
				) : (
					<p>Captions are only available to newer videos. Upload a new video to add captions.</p>
				)}
			</Section>
		</Container>
	)
}

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`

const Section = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`

const Header = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
	margin-top: 24px;
	margin-bottom: 10px;
`

const Subtitle = styled.h2`
	font-weight: 600;
	font-size: 24px;
	color: black;
`

const Description = styled.p`
	color: black;
`

const TagSettingsButton = styled(Button)`
	width: 24px;
	height: 24px;
`

export default EditVideoPage
