import { type DragEndEvent } from "@dnd-kit/core"
import { arrayMove } from "@dnd-kit/sortable"
import { useLayoutEffect, useState } from "react"
import styled from "styled-components"

import Button from "~/components/Button"
import { AddIcon, BackIcon, SettingsIcon } from "~/components/Icon"
import { useAlert } from "~/contexts/AlertContext"
import SortableListContext from "~/contexts/SortableListContext"
import useProductPublishedAlert from "~/hooks/useProductPublishedAlert"
import routes from "~/utilities/routes"
import { primaryColor, primaryLightColor } from "~/utilities/styles"
import trpc from "~/utilities/trpc"

import Chapter from "./Chapter"

const Sidebar: React.FC<{
	course: {
		id: number
		title: string
		isPublished: boolean
		chapters: { id: number; title: string; pageIds: number[] }[]
	}
	reloadCourse: () => Promise<unknown>
	selectedChapterId: number | null
	setSelectedChapterId: (chapterId: number) => void
	onCreateChapterClick: () => void
}> = ({ course, reloadCourse, selectedChapterId, setSelectedChapterId, onCreateChapterClick }) => {
	const alert = useAlert()
	const publishedAlert = useProductPublishedAlert("course")
	const [chaptersOrder, setChaptersOrder] = useState<number[]>([])

	useLayoutEffect(() => {
		setChaptersOrder(course.chapters.map(chapter => chapter.id))
	}, [course.chapters])

	const handlePublishCourse = async () => {
		const dialog = await alert.confirm(
			"Publish course",
			"Are you sure you want to publish this course? This action cannot be undone.",
		)
		if (!dialog.result) return

		await trpc.course.publish.mutate(course.id)
		await reloadCourse()

		dialog.close()

		await publishedAlert.show({ itemId: course.id })
	}

	const handleChapterDragEnd = async (event: DragEndEvent) => {
		const { active, over } = event
		if (over === null || over.id === active.id) return

		const newOrder = arrayMove(
			chaptersOrder,
			chaptersOrder.indexOf(Number(active.id)),
			chaptersOrder.indexOf(Number(over.id)),
		)
		setChaptersOrder(newOrder)

		for (const chapter of course.chapters) {
			trpc.course.updateChapter.mutate({ id: chapter.id, data: { sortOrder: newOrder.indexOf(chapter.id) } })
		}
	}

	return (
		<Container>
			<Header>
				<BackButton onClick={routes.course.index()}>
					<BackButtonIcon />
					Courses
				</BackButton>
				<SettingsButton onClick={routes.course.settings(course.id)}>
					<SettingsIcon />
				</SettingsButton>
			</Header>
			<CourseTitle>{course.title}</CourseTitle>
			<Divider />
			{!course.isPublished && (
				<PublishCourseButton onClick={handlePublishCourse}>Publish course</PublishCourseButton>
			)}
			<ChaptersHeader>
				<ChaptersTitle>Chapters</ChaptersTitle>
				<CreateChapterButton onClick={onCreateChapterClick}>
					<CreateChapterIcon />
				</CreateChapterButton>
			</ChaptersHeader>
			<div>
				<SortableListContext items={chaptersOrder} onDragEnd={handleChapterDragEnd}>
					{[...course.chapters]
						.sort((a, b) => chaptersOrder.indexOf(a.id) - chaptersOrder.indexOf(b.id))
						.map(chapter => (
							<Chapter
								key={chapter.id}
								isSelected={selectedChapterId === chapter.id}
								onClick={() => setSelectedChapterId(chapter.id)}
								chapter={chapter}
							/>
						))}
				</SortableListContext>
			</div>
		</Container>
	)
}

const Container = styled.div`
	flex: 0 0 285px;
	background-color: ${primaryLightColor};
	padding: 24px 0;
	display: flex;
	flex-direction: column;
`

const Header = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 0 20px 16px;
`

const BackButton = styled(Button)`
	font-weight: 600;
	font-size: 14px;
	display: flex;
	align-items: center;
`

const BackButtonIcon = styled(BackIcon)`
	width: 24px;
	height: 24px;
	margin-right: 8px;
`

const SettingsButton = styled(Button)`
	width: 24px;
	height: 24px;
	color: ${primaryColor};
`

const CourseTitle = styled.p`
	font-size: 18px;
	font-weight: 600;
	margin: 0 20px 16px;
`

const Divider = styled.hr`
	border: none;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	margin: 0 20px 16px;
`

const PublishCourseButton = styled(Button).attrs({ variant: "primary" })`
	margin: 0 20px 16px;
`

const ChaptersHeader = styled.div`
	margin: 0 20px 32px;
	display: flex;
	justify-content: space-between;
	align-items: center;
`

const ChaptersTitle = styled.h2`
	font-weight: 600;
	font-size: 18px;
`

const CreateChapterButton = styled(Button)`
	width: 24px;
	height: 24px;
	color: white;
	background-color: ${primaryColor};
	border-radius: 16px;
	display: flex;
	justify-content: center;
	align-items: center;
`

const CreateChapterIcon = styled(AddIcon)`
	width: 18px;
	height: 18px;
`

export default Sidebar
